import { useSelector } from 'react-redux';

import { RootState } from 'store';

import type { FuturesMagazineData } from 'types/futures';

export const useMagazine = (id: number) => {
  const allList = useSelector((state: RootState) => state.futures.magazine);

  const list: FuturesMagazineData[] = id
    ? allList.filter(article => article.futures.includes(id))
    : [];
  return { list };
};
