import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { SitemapData, HistoryData } from 'types/sitemap';
type State = {
  initialize: boolean; // 初期化済（オープニング再生済）フラグ
  previousPage?: HistoryData; // 直前に表示していたページ
  currentPage?: SitemapData; // 現在表示しているページ
  baseColor: 'light' | 'dark'; // 背景色
  changeColor: boolean; // 黒↔白 の背景色変更中フラグ
  defaultTransition: boolean; // ページ遷移全般のトランジション実行中フラグ
  futureTransition: boolean; // TOP → 13の未来詳細 のトランジション実行中フラグ
};

const initialState: State = {
  initialize: false,
  baseColor: 'dark',
  changeColor: true,
  defaultTransition: false,
  futureTransition: false,
};

const user = createSlice({
  name: 'user',

  initialState,

  reducers: {
    // 初期ロード済判定を更新
    updateInitialize: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        initialize: action.payload,
      };
    },
    // 直前のページ情報を更新
    updatePreviousPage: (state, action: PayloadAction<HistoryData>) => {
      return {
        ...state,
        previousPage: action.payload,
      };
    },
    // 今いるページのタイトルを更新
    updateCurrentPage: (state, action: PayloadAction<SitemapData>) => {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
    // ベースカラーを更新
    updateBaseColor: (state, action: PayloadAction<'light' | 'dark'>) => {
      return {
        ...state,
        baseColor: action.payload,
      };
    },
    // artworkGLのベースカラー変更中フラグを更新
    updateChangeColor: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        changeColor: action.payload,
      };
    },
    // artworkGLの通常トランジション実行中フラグを更新
    updateDefaultTransition: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        defaultTransition: action.payload,
      };
    },
    // artworkGLのTOP → 13の未来詳細トランジション実行中フラグを更新
    updateFutureTransition: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        futureTransition: action.payload,
      };
    },
  },
});

// Action Creator
export const {
  updateInitialize,
  updatePreviousPage,
  updateCurrentPage,
  updateBaseColor,
  updateChangeColor,
  updateDefaultTransition,
  updateFutureTransition,
} = user.actions;

// Reducer
export default user.reducer;
