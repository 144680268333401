import { FC, useRef, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

import styles from './FuturesTitle.module.scss';

type FuturesTitleProps = {
  body: string;
  addClass?: string[];
  rootMargin?: string;
};

export const FuturesTitle: FC<FuturesTitleProps> = ({
  body,
  addClass = [],
  rootMargin = '-160px',
}) => {
  const { changeColor, defaultTransition, futureTransition } = useSelector(
    (state: RootState) => state.user
  );

  const item = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  useEffect(() => {
    const { current } = item;
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          setIsIntersecting(entry.isIntersecting); // 交差しているかどうか
          if (entry.isIntersecting) observer.unobserve(entry.target);
        });
      },
      { rootMargin: rootMargin } // しきい値
    );
    if (current === null) return;
    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      ref={item}
      data-intersecting={
        !isIntersecting || changeColor || defaultTransition || futureTransition ? 'false' : 'true'
      }
      className={[styles.title, ...addClass].join(' ')}
      dangerouslySetInnerHTML={{ __html: body }}
    ></div>
  );
};
