import { FC } from 'react';

import { Link } from 'react-router-dom';

import { RelationFutures } from '../RelationFutures';

import styles from './ProjectItem.module.scss';

import type { ProjectData } from 'types/project';

export const ProjectItem: FC<ProjectData> = ({ id, image, title, description, futures }) => {
  return (
    <Link key={id} to={`/project/${id}`} className={styles.projectItem}>
      <figure className={styles.figure}>
        <img src={image || `/assets/no-image.webp`} alt="" className={styles.image} />
      </figure>
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
      </div>

      <p className={styles.description}>{description.join('')}</p>
      <ul className={styles.futuresList}>
        {futures.map(no => (
          <RelationFutures key={no} no={no} />
        ))}
      </ul>
    </Link>
  );
};
