import { createSlice } from '@reduxjs/toolkit';

import list from 'data/report.json';

import type { ReportIndexData } from 'types/report';
type State = {
  list: ReportIndexData[];
};

const initialState: State = {
  list,
};

const report = createSlice({
  name: 'report',

  initialState,

  reducers: {},
});

// Action Creator
// export const {} = report.actions;

// Reducer
export default report.reducer;
