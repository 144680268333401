import { FC } from 'react';

import { LayoutHeader } from 'components/layout/LayoutHeader';
import { GlobalNavigation } from 'components/parts/GlobalNavigation';

import styles from './LayoutNavigation.module.scss';

type LayoutNavigationProps = {
  base?: 'dark' | 'light';
  addClass?: string[];
};

export const LayoutNavigation: FC<LayoutNavigationProps> = ({ base = 'light', addClass = [] }) => {
  return (
    <div className={[styles.layoutNavigation, ...addClass].join(' ')}>
      <LayoutHeader base={base} />
      <div className={styles.screen}></div>
      <GlobalNavigation base={base} />
    </div>
  );
};
