import { FC, useEffect, useState } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { Layout } from 'components/layout/Layout';
import { Loading } from 'components/layout/Loading';
import {
  updateInitialize,
  updateBaseColor,
  updateChangeColor,
  updateDefaultTransition,
  updateFutureTransition,
} from 'store/user';

import { About } from 'components/pages/About';
import { Events } from 'components/pages/Events';
import { Futures } from 'components/pages/Futures';
import { FuturesDetail } from 'components/pages/FuturesDetail';
import { Home } from 'components/pages/Home';
import { NotFound } from 'components/pages/NotFound';
import { Project } from 'components/pages/Project';
import { ProjectDetail } from 'components/pages/ProjectDetail';
import { ProjectPreview } from 'components/pages/ProjectPreview';
import { Reports } from 'components/pages/Reports';
import { Update } from 'components/pages/Update';

import artworkGLData from 'data/artworkGL.json';
import artworkGLData_iOS from 'data/artworkGL_iOS.json';

import { useGetScrollBarWidth } from 'hooks/useGetScrollBarWidth';
import { usePageType } from 'hooks/usePageType';

import type Artwork from 'types/artwork';

// artworkGLの型定義を追加
declare global {
  interface Window {
    artworkGL: Artwork.GLData;
  }
}

export const RoutesApp: FC = () => {
  const dispatch = useDispatch();

  // 読込が完了しコンテンツ表示可能な状態を表すフラグ
  const [finishSetup, setFinishSetup] = useState<boolean>(false);

  // URLからページの種類を判定
  const getPageType = usePageType;
  const [isIosSafari, setIsIosSafari] = useState<null | boolean>(null);

  // iOS SafariはartworkGLへ連携する画像のpxサイズを抑えないとクラッシュするため分岐させる
  useEffect(() => {
    setIsIosSafari(
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /iPad|iPhone|iPod/i.test(navigator.userAgent)
    );
    // OSやブラウザの分岐をbodyへ記憶させる
    document.body.dataset.isIos = String(/iPad|iPhone|iPod/i.test(navigator.userAgent));
    document.body.dataset.isSafari = String(
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    );
    document.body.dataset.isWindows = String(/Windows/i.test(navigator.userAgent));
  }, []);

  // WEB GLへ渡すデータ一覧のjsonを読み込めたら処理を開始する
  useEffect(() => {
    // artworkGLがインスタンス化されており、iOS Safariかどうかの判定が完了している
    if (window.artworkGL && isIosSafari !== null) {
      // 読込対象のjsonをimportできている
      if (artworkGLData && artworkGLData_iOS)
        window.artworkGL.load(isIosSafari ? artworkGLData_iOS : artworkGLData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIosSafari, artworkGLData, artworkGLData_iOS]);

  // artworkGL.onは確実に1回だけ実行したいのでLayoutコンポーネントではなくroutes.tsxに記述している
  useEffect(() => {
    // ローディングが完了したら実行するイベント
    window.artworkGL.on('FINISH_LOADING', () => {
      // コンテンツ表示可能フラグを更新
      setFinishSetup(true);
    });

    // WEB GLのトランジション終了時に発火するイベント
    window.artworkGL.on('FINISH_TRANSITON_WEBGL', _ => {
      // トランジション完了フラグを更新
      dispatch(updateDefaultTransition(false));

      // 保険としてTOP → 13の未来詳細 のトランジション終了フラグも下ろす
      dispatch(updateFutureTransition(false));
    });

    // TOP → 13の未来詳細 のトランジション終了時に発火するイベント
    window.artworkGL.on('BEFORE_MOVING_ILLUST', _ => {
      // 状態管理へ反映
      dispatch(updateFutureTransition(false));
    });

    // WEB GLのベースカラー変更終了時に発火するイベント
    window.artworkGL.on('CHANGE_BG_COLOR', detail => {
      const pageType = getPageType();
      const baseColor: 'light' | 'dark' = detail.isBlack ? 'dark' : 'light';

      // トランジション完了前にベースカラー変更をともなう遷移を繰り返すとCHANGE_BG_COLORが複数回発火するため、条件分岐をはさんでフラグの裏返りを抑制
      const isValid =
        (pageType === 'index' && baseColor === 'dark') ||
        (pageType !== 'index' && baseColor === 'light');

      if (isValid) {
        // 状態管理へ反映
        dispatch(updateBaseColor(baseColor));
        dispatch(updateChangeColor(false));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ローディングアニメーション再生とWEB GLの初期化が両方とも完了したら実行
  useEffect(() => {
    if (finishSetup) {
      // 最初に表示するコンテンツの情報を整理
      const pathname = new URL(window.location.href).pathname;
      const firstPage = pathname.split('/')[1];
      const type = getPageType();

      // 最初のページのトランジションのためのパラメータを準備
      const initOptions: Artwork.InitFirstPageOptionsData =
        type === 'index'
          ? { firstPage: 'index', type }
          : type === 'future'
          ? {
              firstPage: 'future_' + +pathname.split('/')[2],
              futureNum: +pathname.split('/')[2] - 1,
              type: 'future',
            }
          : { firstPage, type };

      // 最初のページはindexではない場合(ベースカラー = light)
      if (type !== 'index') {
        // 初回表示済みフラグを立てる
        dispatch(updateInitialize(true));
        // ベースカラー設定: トランジション開始前にナビがlightになっているほうが初回表示が自然になる
        dispatch(updateBaseColor('light'));
      }

      // 最初のページへのトランジションを開始
      setTimeout(() => {
        window.artworkGL.emit('INIT_FIRST_PAGE', { ...initOptions });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishSetup]);

  // css variablesにスクロールバーの幅をセット
  const scrollBarWidth = useGetScrollBarWidth();
  useEffect(() => {
    // Get the scrollbar dimension
    const scrollbarWidth = scrollBarWidth();
    // Set a custom property with the value we calculated
    document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      {finishSetup ? (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/futures" element={<Futures />} />
            <Route path="/futures/:id" element={<FuturesDetail />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/events" element={<Events />} />
            <Route path="/update" element={<Update />} />
            <Route path="/project" element={<Project />} />
            <Route path="/project-preview/:slug" element={<ProjectPreview />} />
            <Route path="/project/:slug" element={<ProjectDetail />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      ) : (
        <Loading />
      )}
    </BrowserRouter>
  );
};
