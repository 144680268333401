import { FC, useEffect, useState, useRef, memo } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

// import cellParams from './cellParams.json';
// import pixelateImg from './images/img_1.webp';
import styles from './PixelateEffect.module.scss';

// import { PixelateMask } from './PixelateMask';

// import usePixelateEffect from './usePixelateEffect';

type PixelateEffectProps = {
  no: number;
};
/**
 *
 * @param { number } no - 13の未来の番号
 * @returns { ReactNode } 画像の前面へ被せるモザイクエフェクト
 */

export const PixelateEffect: FC<PixelateEffectProps> = ({ no }) => {
  const { changeColor, defaultTransition, futureTransition } = useSelector(
    (state: RootState) => state.user
  );
  // const cellParams = usePixelateEffect(20, 10);

  // const item = useRef(null);
  const mask = useRef<SVGSVGElement>(null);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  useEffect(() => {
    const { current } = mask;
    // const { current } = mask;
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          setIsIntersecting(entry.isIntersecting); // 交差しているかどうか
          if (entry.isIntersecting) observer.unobserve(entry.target);
        });
      },
      { rootMargin: '-30%' } // しきい値
    );
    if (current === null) return;
    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, []);

  const PixelateMaterial = memo(() => (
    <>
      <g className={styles.group}>
        <rect className={styles.cell} x="4" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="9" width="1" height="1" fill="white"></rect>
      </g>
      <g className={styles.group}>
        <rect className={styles.cell} x="0" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="9" width="1" height="1" fill="white"></rect>
      </g>
      <g className={styles.group}>
        <rect className={styles.cell} x="8" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="10" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="9" width="1" height="1" fill="white"></rect>
      </g>
      <g className={styles.group}>
        <rect className={styles.cell} x="2" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="14" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="0" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="7" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="1" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="13" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="2" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="3" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="4" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="12" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="4" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="2" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="5" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="6" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="0" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="1" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="8" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="15" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="7" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="3" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="9" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="16" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="18" y="8" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="5" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="6" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="11" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="17" y="9" width="1" height="1" fill="white"></rect>
        <rect className={styles.cell} x="19" y="9" width="1" height="1" fill="white"></rect>
      </g>
    </>
  ));

  return (
    <>
      {/* <svg
        className={styles.pixelateEffect}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 10"
        preserveAspectRatio="none"
      >
        <defs>
          <mask id="mask">
            {[0, 1, 2, 3].map(group => (
              <g key={group}>
                {cellParams.map((grid, index) => {
                  const x = index % 20;
                  const y = Math.floor(index / 20);
                  return grid === group ? (
                    <rect  className={styles.cell} key={index} x={x} y={y} width="1" height="1" fill="white" />
                  ) : null;
                })}
              </g>
            ))}
          </mask>
        </defs>
        <image href={pixelateImg} width="20" height="10" mask="url(#mask)" />
      </svg> */}
      {/* <div
        ref={item}
        className={styles.pixelateEffect}
        data-intersecting={
          !isIntersecting || changeColor || defaultTransition || futureTransition ? 'false' : 'true'
        }
      >
        {cellParams.map((grid, index) => (
          <div key={index} className={styles.cell} data-param={grid}></div>
        ))}
      </div> */}
      <svg
        className={styles.pixelateEffect}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 10"
        preserveAspectRatio="none"
        ref={mask}
        data-intersecting={
          !isIntersecting || changeColor || defaultTransition || futureTransition ? 'false' : 'true'
        }
      >
        <defs>
          <mask id={`mask_alpha-${no}`}>
            <PixelateMaterial />
          </mask>
          <mask id={`mask_brightness-${no}`}>
            <PixelateMaterial />
          </mask>
        </defs>
        <image
          href={require(`components/parts/PixelateEffect/images/img_${no}.webp`)}
          width="20"
          height="10"
          mask={`url(#mask_alpha-${no})`}
        />
        <rect
          stroke="white"
          strokeWidth="0.1"
          width="20"
          height="10"
          fill="white"
          mask={`url(#mask_brightness-${no})`}
        />
        <rect
          stroke="white"
          strokeWidth="0.1"
          className={styles.whiteScreen}
          width="20"
          height="10"
          fill="white"
        />
      </svg>
    </>
  );
};
