import { FC } from 'react';

import { ProjectItem } from 'components/pages/Project/components/ProjectItem';

import { Headline } from '../Headline';

import styles from './Project.module.scss';

import { useProject } from './useProject';

import type { ProjectData } from 'types/project';

type ProjectProps = {
  id: number;
  addClass?: string[];
};
export const Project: FC<ProjectProps> = ({ id, addClass = [] }) => {
  const { list } = useProject(id);
  return list.length ? <ProjectView addClass={addClass} list={list} /> : <></>;
};

type ProjectViewProps = {
  list: ProjectData[];
  addClass?: string[];
};
export const ProjectView: FC<ProjectViewProps> = ({ list, addClass = [] }) => {
  return (
    <article className={[styles.project, ...addClass].join(' ')}>
      <Headline title="PROJECT" subTitle="プロジェクト" />
      <div className={styles.body}>
        {list.map(project => (
          <ProjectItem key={project.id} {...project} />
        ))}
      </div>
    </article>
  );
};
