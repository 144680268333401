import { createSlice } from '@reduxjs/toolkit';

import list from 'data/update.json';

import type { UpdateIndexData } from 'types/update';

type State = {
  list: UpdateIndexData[];
};

const initialState: State = {
  list: list as UpdateIndexData[],
};

const update = createSlice({
  name: 'update',

  initialState,

  reducers: {},
});

// Action Creator
// export const { } = update.actions;

// Reducer
export default update.reducer;
