import { FC } from 'react';

import { EventItem } from 'components/pages/Events/components/EventItem';

import { Headline } from '../Headline';

import styles from './Event.module.scss';

import { useEvent } from './useEvent';

import type { EventIndexData } from 'types/event';

type EventProps = {
  id: number;
  addClass?: string[];
};

export const Event: FC<EventProps> = ({ id, addClass = [] }) => {
  const { list } = useEvent(id);
  return list.length ? <EventView addClass={addClass} list={list} /> : <></>;
};

type EventViewProps = {
  list: EventIndexData[];
  addClass?: string[];
};
export const EventView: FC<EventViewProps> = ({ list, addClass = [] }) => {
  return (
    <article className={[styles.event, ...addClass].join(' ')}>
      <Headline title="EVENTS" subTitle="イベント" />
      <div className={styles.list}>
        {list.map(item => (
          <EventItem key={item.id} {...item} />
        ))}
      </div>
    </article>
  );
};
