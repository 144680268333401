import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { updateCurrentPage } from 'store/user';

import type { SitemapData } from 'types/sitemap';

// ページタイトル変更
export const usePageTitle = (pageTitle: string): void => {
  const dispatch = useDispatch();
  // 初回表示済フラグ
  const { indexList } = useSelector((state: RootState) => state.sitemap);

  // 接尾辞
  const suffix = 'Future Explorations | 100BANCH';

  useEffect(() => {
    // ページ名称を状態管理に登録
    const currentPage: SitemapData = indexList.find(item => item.label === pageTitle) || {
      label: pageTitle,
      copy: '',
      slug: '',
    };
    dispatch(updateCurrentPage(currentPage));
    // titleタグへ設定
    document.title =
      pageTitle === process.env.REACT_APP_WEBSITE_TITLE
        ? process.env.REACT_APP_WEBSITE_TITLE
        : `${pageTitle} | ${suffix}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle]);
};
