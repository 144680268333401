import { FC, useEffect, useState, useRef } from 'react';

import styles from './MarqueeCatchphrase.module.scss';

type MarqueeCatchphraseProps = {
  text: string;
  addClass?: string[];
};

export const MarqueeCatchphrase: FC<MarqueeCatchphraseProps> = ({ text, addClass = [] }) => {
  const line = useRef<HTMLDivElement>(null);
  const item = useRef<HTMLSpanElement>(null);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const speed = 7.5; // 文字列の速度 ... 1pxの移動に何msかけるか

  /**
   * @document
   *  文字の親要素lineの幅を文字の幅として、文字幅だけ左へ移動すると最初へ巻き戻してループのように見せる
   *  lineの幅は文字列1回分だが、枠外を非表示にしていないので、隣の文字列が連続して追従しているように見える
   *  Web Animations APIでleftを-1 * widthに変化させる
   *  transform: translateX(-100%); で実装した場合、Safariでループの切れ目で点滅が入る理由からleftで実装
   */
  useEffect(() => {
    const initial = (width: number) => {
      setWidth(item.current?.offsetWidth || 0); // 文字列の幅を取得
      setDuration(width * speed); // アニメーション速度
    };
    initial(item.current?.offsetWidth || 0);

    const resizeObserver = new ResizeObserver(entries => {
      // entriesは観察対象の全ての要素を含む配列
      // 今回は一つだけ観察対象があるので、entries[0]を使用
      initial(entries[0].contentRect.width || 0);
    });

    // item.currentに観察対象のDOM要素を指定
    if (item.current) resizeObserver.observe(item.current);
  }, [item, width]);

  // Web Animations API
  useEffect(() => {
    const marqueeKeyframes = [{ left: 0 }, { left: `${width * -1}px` }];
    const marqueeTiming = { duration: duration, iterations: Infinity, easing: 'linear' };

    const { current } = line;
    if (!current) return;
    const marqueeAnimation = current.animate(marqueeKeyframes, marqueeTiming);
    marqueeAnimation[isIntersecting ? 'play' : 'pause']();
  }, [isIntersecting, line, width, duration]);

  useEffect(() => {
    const { current } = line;
    const rootMargin = '20%';
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          setIsIntersecting(entry.isIntersecting); // 交差しているかどうか
          if (entry.isIntersecting) observer.unobserve(entry.target);
        });
      },
      { rootMargin: rootMargin } // しきい値
    );
    if (current === null) return;
    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={[styles.marqueeCatchphrase, ...addClass].join(' ')}>
      <div className={styles.frame}>
        <div
          data-intersecting={isIntersecting ? 'true' : 'false'}
          className={styles.line}
          ref={line}
          style={{ width: width, willChange: 'left' }} // willChangeを指定しておくと、アニメーションが始まる前にブラウザが最適化を行う
        >
          <span className={styles.text} aria-hidden="true">
            {text}&emsp;
          </span>
          <span className={styles.text} ref={item}>
            {text}&emsp;
          </span>
          <span className={styles.text} aria-hidden="true">
            {text}&emsp;
          </span>
        </div>
      </div>
    </div>
  );
};
