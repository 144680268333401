import { FC, useEffect, useState, useRef } from 'react';

import { useParams, Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { LayoutHeader } from 'components/layout/LayoutHeader';
import { RootState } from 'store';

import { usePageTitle } from 'hooks/usePageTitle';
import { useToPlainText } from 'hooks/useToPlainText';

import styles from './FuturesDetail.module.scss';

import { Event } from './components/Event';
import { Introduction } from './components/Introduction';
import { Magazine } from './components/Magazine';
import { MarqueeCatchphrase } from './components/MarqueeCatchphrase';
import { Project } from './components/Project';
import { Report } from './components/Report';
import { Signal } from './components/Signal';

export const FuturesDetail: FC = () => {
  // パラメータを取得
  const { id } = useParams<string>();
  const idNumber = id ? +id : 1;

  // 13の未来データを取得
  const { indexList } = useSelector((state: RootState) => state.futures);

  const toPlainText = useToPlainText;

  const currentTitle = indexList.find(index => index.no === idNumber);

  const relationArticle = useRef<HTMLDivElement>(null);
  const [isEventEmpty, setIsEventEmpty] = useState<boolean>(false);
  const beforeGarageArea = useRef<HTMLDivElement>(null);
  const [isBeforeGarageEmpty, setIsBeforeGarageEmpty] = useState<boolean>(false);

  useEffect(() => {
    // eventが空のとき空欄と判定
    const area = relationArticle.current;
    const lastChild = area?.lastElementChild;
    setIsEventEmpty(lastChild?.tagName !== 'ARTICLE');
    // reportとprojectが空のときの空欄判定
    const beforeGarage = beforeGarageArea.current;
    const beforeGarageElement = beforeGarage?.previousElementSibling;
    setIsBeforeGarageEmpty(beforeGarageElement?.tagName !== 'ARTICLE');
  }, []);

  // titleタグの設定
  usePageTitle(
    currentTitle
      ? toPlainText(<div dangerouslySetInnerHTML={{ __html: currentTitle.body }}></div>)
      : ''
  );

  // slugと紐づく記事がない場合は404ページへリダイレクト
  return !currentTitle ? (
    <Navigate replace to="/404" />
  ) : (
    <>
      <div className={styles.rectangleDecoration}></div>
      {/* モバイルレイアウトでヘッダーだけ本文背後へ潜らせフローティングメニューは前面へ残すので、ダミーのヘッダーを設置する */}
      <LayoutHeader base="light" addClass={[styles.dummyHeader]} />
      <div className={styles.futuresDetail}>
        <div className={styles.header}>
          <div className={[styles.stripe, styles.afterHeader].join(' ')}></div>
          <Introduction
            id={idNumber}
            keyword={currentTitle.keyword}
            title={currentTitle.body}
            body={currentTitle.concept}
          />
        </div>
        <MarqueeCatchphrase text={currentTitle.enSubTitle} />
        <div className={styles.bgLightness}>
          <div
            ref={relationArticle}
            className={[
              styles.bgWrapper,
              isEventEmpty ? styles['--isEventEmpty'] : '',
              isBeforeGarageEmpty ? styles['--isBeforeGarageEmpty'] : '',
            ].join(' ')}
          >
            <div className={[styles.stripe, styles.beforeBgWrapper].join(' ')}></div>
            <figure className={styles.bg}>
              <img
                src={require(`components/pages/FuturesDetail/images/img_${idNumber}.webp`)}
                alt=""
                className={styles.bgImage}
              />
            </figure>
            <div className={styles.bgLightnessSpacer}></div>

            <Project id={idNumber} />
            <Report id={idNumber} />
            <div
              ref={beforeGarageArea}
              className={[
                styles.stripe,
                styles.beforeGarageProgram,
                isEventEmpty ? styles['--isEventEmpty'] : '',
                isBeforeGarageEmpty ? styles['--isBeforeGarageEmpty'] : '',
              ].join(' ')}
            ></div>
            <div className={styles.garageWrap}>
              <div className={styles.garageProgram}>
                <Signal id={idNumber} />
                <Magazine id={idNumber} />
              </div>
            </div>
            <Event id={idNumber} />
          </div>
        </div>
      </div>
    </>
  );
};
