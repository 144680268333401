import { useState, useMemo, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

export const usePagination = (totalCount: number, startPage: number, max: number) => {
  const navigate = useNavigate();
  const location = useLocation();

  // ページ数
  const pages = Math.ceil(totalCount / max);
  const [currentPage, setCurrentPage] = useState(startPage);

  // 5ページ以上で カレントが前半
  const isEarly = useMemo((): boolean => !(pages <= 5) && currentPage < 4, [pages, currentPage]);

  // 5ページ以上で カレントが終盤
  const isEnd = useMemo(
    (): boolean => !(pages <= 5) && currentPage > pages - 3,
    [pages, currentPage]
  );

  // 5ページ以上でカレントが中盤
  const isSeparate = useMemo(
    () => !(pages <= 5) && !(currentPage < 4) && !(currentPage > pages - 3),
    [pages, currentPage]
  );

  // ページ番号をクエリパラメータとして追加
  useEffect(() => {
    // ページ遷移時にスクロール位置をトップに戻す
    const parentArea = document.querySelector('[class*="Layout_body"]');
    parentArea?.scrollTo(0, 0);

    // 現在のpageパラメータの値を取得
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('page') || '1';

    // historyの重複登録を防ぐため、pageパラメータとcurrentPageの値を比較
    // 値が異なる場合、currentPageの値をpageパラメータに設定
    if (+page !== currentPage) {
      searchParams.set('page', currentPage + '');
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // 表示するボタン
  const visiblePages = useMemo((): (number | '…')[] => {
    const result: (number | '…')[] = [];

    if (pages <= 5) {
      // 5ページ以下
      for (let i = 1; i <= pages; i++) result.push(i);
    } else if (isEarly) {
      // 1〜3ページ
      for (let i = 1; i <= 4; i++) result.push(i);
      result.push('…');
      result.push(pages);
    } else if (isEnd) {
      // 最終-3〜最終ページ
      result.push(1);
      result.push('…');
      for (let i = 3; i >= 0; i--) result.push(pages - i);
    } else {
      result.push(1);
      result.push('…');
      for (let i = -1; i <= 1; i++) result.push(currentPage + i);
      result.push('…');
      result.push(pages);
    }
    return result;
  }, [pages, isEarly, isEnd, currentPage]);

  return { pages, visiblePages, currentPage, setCurrentPage, isEarly, isEnd, isSeparate };
};
