import { forwardRef } from 'react';

import styles from './CloseUpCursor.module.scss';

type CloseUpCursorProps = {
  addClass?: string[];
};
export const CloseUpCursor = forwardRef<HTMLDivElement, CloseUpCursorProps>(
  ({ addClass = [] }, ref) => {
    return (
      <div className={[styles.closeUpCursor, ...addClass].join('')} ref={ref}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" role="img">
          <polygon
            fill="#ffffff"
            points="71.6,55.1 70.6,58.8 86.3,62.9 48.9,84.5 50.8,87.8 88.1,66.2 84,81.9 87.6,82.8 93.5,61 "
          />
        </svg>
      </div>
    );
  }
);
