import { LayoutNavigation } from 'components/layout/LayoutNavigation';

import styles from './CopyArea.module.scss';

export const CopyArea = () => {
  return (
    <div className={styles.copyArea}>
      <LayoutNavigation base="light" addClass={[styles.layoutNavigation]} />
      <article className={styles.article}>
        <header>
          <h2 className={styles.title}>ここからが始まりである。</h2>
        </header>
        <p className={styles.paragraph}>
          そもそも未来など目に見えるものではない。
          <br />
          未来への兆しはサイン(sign)として視覚的に捉えるものというよりも、
          <br />
          サイン(sine;正弦波)のように目には見えないが確かに存在する波動として
          <br className="showWide" />
          「感じられる」ものなのかもしれない。
        </p>
        <p className={styles.paragraph}>
          100BANCH発「つくりたい未来」の
          <br className="showNarrow" />
          総合と可視化を試みたこのチャレンジが、
          <br />
          未来を志向する全ての人々への、思索と試作活動の刺激剤となればとてもうれしい。
        </p>
      </article>
    </div>
  );
};
