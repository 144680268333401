import { FC } from 'react';

import { CategoryNavigation } from 'components/parts/CategoryNavigation';
import { WebSiteName } from 'components/parts/WebSiteName';

import styles from './LayoutFooter.module.scss';

import { Breadcrumbs } from './components/Breadcrumbs';
import { SnsLinks } from './components/SnsLinks';

type LayoutFooterProps = {
  addClass?: string[];
};

export const LayoutFooter: FC<LayoutFooterProps> = ({ addClass = [] }) => {
  const noop = (): void => {
    // 何もしない
  };
  return (
    <>
      <Breadcrumbs addClass={addClass} />
      <div className={[styles.layoutFooter, ...addClass].join(' ')}>
        <div className={styles.title}>
          <WebSiteName base="dark" />
        </div>
        <div className={styles.sns}>
          <SnsLinks />
        </div>
        <div className={styles.categoryNavigation}>
          <CategoryNavigation close={noop} minimum={true} base="dark" />
        </div>
      </div>
    </>
  );
};
