/**
 * ZeroPadding
 * @document 数値を渡すと2桁の0詰め文字列を返します
 * @param num
 * @returns string
 */
const useZeroPadding = (num: number): string => {
  if (num >= 0 && num < 10) {
    return '0' + num;
  } else if (num >= 10) {
    return String(num);
  } else if (num > -10 && num < 0) {
    return '-0' + Math.abs(num);
  }
  return String(num);
};

export default useZeroPadding;
