import { useSelector } from 'react-redux';

import { RootState } from 'store';

import type { ReportIndexData } from 'types/report';

export const useReport = (id: number) => {
  const allList = useSelector((state: RootState) => state.report.list);
  const dummy: ReportIndexData[] = allList.filter(article => article.futures.includes(id));
  return { list: dummy };
};
