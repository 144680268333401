import { FC, useMemo } from 'react';

import { useLocation, Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Pagination } from 'components/parts/Pagination';
import { RootState } from 'store';

import { usePageTitle } from 'hooks/usePageTitle';

import styles from './Project.module.scss';

import { ProjectItem } from './components/ProjectItem';

export const Project: FC = () => {
  // URLパラメータから開始ページを取得
  const query = new URLSearchParams(useLocation().search);
  const startPage = query.get('page') || 1;

  // 全ての記事データ
  const allList = useSelector((state: RootState) => state.project.list) || [];

  // 1ページ内に表示する件数
  const max = 10;

  // ページネーションで表示中の記事データ
  const list = useMemo(() => {
    const start = (+startPage - 1) * max;
    const end = start + max;
    return allList.slice(start, end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allList, startPage, max, startPage]);

  // titleタグの設定
  usePageTitle(`PROJECT`);

  // カレントページと紐づく記事がない場合は404ページへリダイレクト
  return list.length === 0 && +startPage !== 1 ? (
    <Navigate replace to="/404" />
  ) : (
    <>
      <div className={styles.project}>
        <div className={styles.read}>
          <p className={styles.paragraph}>
            Future
            Explorationsとして推進する、100BANCHから生まれた未来探究テーマに基づく実践的探索プロジェクトを紹介しています。
          </p>
        </div>
        <div className={styles.list}>
          {list.map(item => (
            <ProjectItem key={item.id} {...item} />
          ))}
        </div>
        <Pagination totalCount={allList.length} startPage={+startPage} max={max} />
      </div>
    </>
  );
};
