import { FC, useEffect, useCallback, useState, useRef } from 'react';

import { useDispatch } from 'react-redux';

import { OrganizationLogo } from 'components/parts/OrganizationLogo';
import { WebSiteName } from 'components/parts/WebSiteName';
import { updateInitialize } from 'store/user';

import styles from './Opening.module.scss';

export const Opening: FC = () => {
  const dispatch = useDispatch();

  // 1: スクリーン表示
  // 2: フェードイン enter
  // 3: フェードイン reave
  // 4: 移動 enter
  // 5: 移動 reave
  const [scene, setScene] = useState<number>(0);

  // Element
  const screen = useRef<HTMLDivElement>(null);
  const organization = useRef<HTMLAnchorElement>(null);
  const logo = useRef<HTMLDivElement>(null);

  // スクリーンのアニメーション
  const screenAnimation = useCallback((event: TransitionEvent) => {
    // scene ロゴフェードイン開始
    setScene(2);

    // イベントを解除
    const screenElement = screen.current;
    if (screenElement) screenElement.removeEventListener('transitionend', screenAnimation);
  }, []);

  // ロゴのアニメーション
  const logoAnimation = useCallback((event: TransitionEvent) => {
    // transformではない場合は処理をスキップ
    if (event.propertyName !== 'transform') return;

    // scene ロゴフェードイン → 移動
    setScene(3);

    // 移動前に少し静止
    const waitTime = 500; // ロゴが静止する時間
    setTimeout(() => {
      // 移動開始
      setScene(4);

      // イベントを解除
      const logoElement = logo.current;
      if (logoElement) {
        logoElement.addEventListener('transitionend', logoAnimationReave);
        logoElement.removeEventListener('transitionend', logoAnimation);
      }
    }, waitTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ロゴの移動完了
  const logoAnimationReave = useCallback((event: TransitionEvent) => {
    // topではない場合は処理をスキップ
    if (event.propertyName !== 'top') return;

    // scene 完了
    setScene(5);

    // イベントを解除
    const logoElement = logo.current;
    if (logoElement) logoElement.removeEventListener('transitionend', logoAnimationReave);
  }, []);

  // 最後までアニメーションしたらフラグを更新
  useEffect(() => {
    if (scene === 5) {
      dispatch(updateInitialize(true));
    }
  }, [dispatch, scene]);

  // ロードで初期化
  useEffect(() => {
    setScene(1);

    // スクリーンが表示されてからロゴをアニメーションさせる
    const screenElement = screen.current;
    if (screenElement) screenElement.addEventListener('transitionend', screenAnimation);

    // ロゴのフェードイン後、移動アニメーションを実行
    const logoElement = logo.current;
    if (logoElement) logoElement.addEventListener('transitionend', logoAnimation);

    return () => {
      if (screenElement) screenElement.removeEventListener('transitionend', screenAnimation);
      if (logoElement) logoElement.removeEventListener('transitionend', logoAnimation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.opening}>
      <div className={styles.screen} aria-hidden={scene === 0} ref={screen}></div>
      {/*
       * フェードインはセンタリングのズーム、移動はtopとleftを起点とした座標移動で
       * 起点が異なるため、それぞれのシーンで別々にElementを設置している。
       */}
      {/* scene: フェードイン */}
      {scene <= 2 && (
        <div className={styles.visible} aria-hidden={scene !== 2}>
          <WebSiteName addClass={[styles.webSiteName]} base="dark" ref={logo} />
        </div>
      )}
      {/* scene: ナビゲーションの位置へ移動するまで */}
      {scene >= 3 && (
        <div className={styles.move} aria-hidden={scene < 4}>
          <OrganizationLogo addClass={[styles.organization]} base="dark" ref={organization} />
          <WebSiteName addClass={[styles.webSiteName]} base="dark" ref={logo} />
        </div>
      )}
    </div>
  );
};
