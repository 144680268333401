import { FC } from 'react';

import { Date } from 'components/parts/Date';

import styles from './MagazineItem.module.scss';

import type { FuturesMagazineData } from 'types/futures';

export const MagazineItem: FC<FuturesMagazineData> = ({ image, title, date, category, link }) => {
  return (
    <a className={styles.magazineItem} href={link}>
      <div className={styles.header}>
        <Date date={date} addClass={[styles.date]} />
        <figure className={styles.figure}>
          <img className={styles.image} src={image || `/assets/no-image.webp`} alt="" />
        </figure>
        <div className={styles.tagList}>
          {category.map(item => (
            <span key={item.id} className={styles.tag}>
              {item.label}
            </span>
          ))}
        </div>
      </div>
      <p className={styles.description}>{title}</p>
    </a>
  );
};
