import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { SitemapData } from 'types/sitemap';

type State = {
  isWritingContentData: boolean;
  indexList: SitemapData[];
};

const initialState: State = {
  isWritingContentData: false,
  indexList: [
    {
      label: 'TOP',
      copy: '',
      slug: 'home',
      isPrimary: true,
    },
    {
      label: 'ABOUT',
      copy: 'Future Explorationsについて',
      slug: 'about',
      isPrimary: true,
    },
    {
      label: '13の未来の当たり前',
      copy: '',
      slug: 'futures',
      isPrimary: true,
    },
    {
      label: 'PROJECT',
      copy: 'プロジェクト',
      slug: 'project',
      isPrimary: true,
    },
    {
      label: 'REPORT',
      copy: 'レポート',
      slug: 'reports',
      isPrimary: true,
    },
    {
      label: 'EVENT',
      copy: 'イベント',
      slug: 'events',
      isPrimary: true,
    },
    {
      label: 'UPDATE',
      copy: 'アップデート',
      slug: 'update',
      isPrimary: true,
    },
    {
      label: '404',
      copy: 'Not Found',
      slug: '',
    },
  ],
};

const sitemap = createSlice({
  name: 'sitemap',

  initialState,

  reducers: {
    // ローディング中フラグを更新
    updateIsWritingContentData: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isWritingContentData: action.payload,
      };
    },
  },
});

// Action Creator
export const { updateIsWritingContentData } = sitemap.actions;

// Reducer
export default sitemap.reducer;
