import { FC } from 'react';

import { Date } from 'components/parts/Date';

import styles from './ReportItem.module.scss';

import type { ReportIndexData } from 'types/report';

export const ReportItem: FC<ReportIndexData> = ({ image, title, project, date, link }) => {
  return (
    <a className={styles.reportItem} href={link}>
      <figure className={styles.figure}>
        <img src={image || `/assets/no-image.webp`} alt="" className={styles.image} />
      </figure>
      <div className={styles.header}>
        <Date date={date} addClass={[styles.date]} />
        <div className={styles.project}>
          {project.map(tag => (
            <span key={tag.id} className={styles.tag}>
              {tag.label}
            </span>
          ))}
        </div>
      </div>
      <p className={styles.title}>
        <span>{title}</span>
      </p>
    </a>
  );
};
