import { FC, Fragment, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Popover, Transition } from '@headlessui/react';
import { CategoryNavigation } from 'components/parts/CategoryNavigation';
import { LocalizedTagline } from 'components/parts/LocalizedTagline';
import { RootState } from 'store';

import styles from './GlobalNavigation.module.scss';

import { FuturesList } from './components/FuturesList';

type GlobalNavigationProps = {
  base?: 'dark' | 'light';
  addClass?: string[];
};
export const GlobalNavigation: FC<GlobalNavigationProps> = ({ base = 'light', addClass = [] }) => {
  const location = useLocation();
  const { initialize, changeColor } = useSelector((state: RootState) => state.user);

  // URLからページの種類を判定
  const [isIndex, setIsIndex] = useState<boolean>(false);
  useEffect(() => {
    setIsIndex(location.pathname === '/');
  }, [location.pathname]);

  // ワイドスクリーンレイアウトのときは開いた状態にする
  const [isWideLayout, setIsWideLayout] = useState<boolean>(false);

  // windowのbreakpointを指定しているmediaQueryListを取得
  useEffect(() => {
    // ブレークポイント
    const breakpoint = {
      narrow: 'screen and (max-width: 900px)',
      wide: 'screen and (min-width: 901px)',
    };
    // ワイドスクリーンレイアウトかどうか判定
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setIsWideLayout(event.matches);
    };

    const wideLayoutMediaQuery = window.matchMedia(breakpoint.wide);

    // マッチの変更時に実行される関数を登録
    wideLayoutMediaQuery.addListener(handleMediaQueryChange);

    // 初回の判定を行うために呼び出す
    setIsWideLayout(wideLayoutMediaQuery.matches);

    // cleanup関数でリスナーの登録を解除
    return () => {
      wideLayoutMediaQuery.removeListener(handleMediaQueryChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popover
      className={[
        styles.globalNavigation,
        ...addClass,
        base && styles[`--${base}`],
        initialize && styles['--initialize'],
      ].join(' ')}
      as="nav"
    >
      {({ open, close }) => (
        <>
          <Popover.Button className={styles.opener}>
            <span className={[styles.openerIcon, open && styles['isOpen']].join(' ')}></span>
            {!open ? 'MENU' : 'CLOSE'}
          </Popover.Button>
          <Transition as={Fragment} show={open || isWideLayout} appear>
            <Popover.Panel className={styles.panel}>
              <Transition.Child
                as={Fragment}
                enter={styles.enter}
                enterFrom={styles.enterFrom}
                enterTo={styles.enterTo}
                leave={styles.leave}
                leaveFrom={styles.leaveFrom}
                leaveTo={styles.leaveTo}
              >
                <div className={styles.screen}></div>
              </Transition.Child>

              <Transition.Child
                unmount={false}
                as={Fragment}
                enter={styles.enter}
                enterFrom={styles.enterFrom}
                enterTo={styles.enterTo}
                leave={styles.leave}
                leaveFrom={styles.leaveFrom}
                leaveTo={styles.leaveTo}
              >
                <div className={styles.indexList}>
                  <CategoryNavigation close={close} base={base} />
                </div>
              </Transition.Child>
              <div
                className={[
                  styles.FuturesList,
                  isIndex || changeColor ? styles['--invisible'] : '',
                ].join(' ')}
              >
                <FuturesList />
              </div>
              <Transition.Child
                unmount={false}
                as={Fragment}
                enter={styles.enter}
                enterFrom={styles.enterFrom}
                enterTo={styles.enterTo}
                leave={styles.leave}
                leaveFrom={styles.leaveFrom}
                leaveTo={styles.leaveTo}
              >
                <div className={styles.tagline}>
                  <LocalizedTagline />
                </div>
              </Transition.Child>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
