import { FC, ReactNode } from 'react';

import styles from './Article.module.scss';

type ArticleProps = {
  addClass?: string[];
  title: ReactNode;
  children: ReactNode;
};

export const Article: FC<ArticleProps> = ({ title, children, addClass = [] }) => {
  return (
    <article className={[styles.article, ...addClass].join(' ')}>
      <header className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
      </header>
      <div className={styles.body}>{children}</div>
    </article>
  );
};
