import { FC, useEffect, useState } from 'react';

import styles from './Loading.module.scss';

type LoadingProps = {
  addClass?: string[];
};
export const Loading: FC<LoadingProps> = ({ addClass = [] }) => {
  const [progress, setProgress] = useState<string>('0');

  useEffect(() => {
    window.artworkGL.on('COUNT_LOADING', detail => {
      setProgress(Math.floor(+detail.progress * 100).toString());
    });
  }, []);

  return (
    <div className={[styles.loading, ...addClass].join(' ')}>
      <div className={styles.progress}>
        <div className={styles.done} style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};
