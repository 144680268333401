import { FC } from 'react';

import styles from './SnsLinks.module.scss';

export const SnsLinks: FC = () => {
  const snsList = [
    {
      label: 'twitter',
      url: 'https://twitter.com/100banch',
    },
    {
      label: 'instagram',
      url: 'https://www.instagram.com/100banch/',
    },
    {
      label: 'facebook',
      url: 'https://www.facebook.com/100banch/',
    },
    {
      label: 'youtube',
      url: 'https://www.youtube.com/channel/UCPGwu7spLAuPz_XlygIiFcQ',
    },
    {
      label: 'peatix',
      url: 'https://peatix.com/group/54888',
    },
  ];
  return (
    <div className={styles.snsLinks}>
      {snsList.map(item => (
        <a
          href={item.url}
          key={item.label}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require(`components/layout/LayoutFooter/components/SnsLinks/images/ico_${item.label}.svg`)}
            alt={item.label}
          />
        </a>
      ))}
    </div>
  );
};
