import { FC } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

import { IndexItem } from '../IndexItem';

import styles from './IndexList.module.scss';

export const IndexList: FC = () => {
  // 13の未来一覧のデータを取得
  const { indexList } = useSelector((state: RootState) => state.futures);
  return (
    <>
      {/* 本文にオーバーレイブレンドのエフェクトを被せるために、全く同じ内容を2回出力し片方を非インタラクティブにしている */}
      <div className={[styles.indexList].join(' ')} aria-hidden="true" role="img">
        {indexList.map(item => (
          <IndexItem key={item.no} {...item} />
        ))}
      </div>
      <div className={[styles.indexList].join(' ')}>
        {indexList.map(item => (
          <IndexItem key={item.no} {...item} />
        ))}
      </div>
    </>
  );
};
