import { FC } from 'react';

import { Link } from 'react-router-dom';

import { usePageTitle } from 'hooks/usePageTitle';

import styles from './About.module.scss';
import txt_futures from './images/txt_futures.svg';

import { Article } from './components/Article';

export const About: FC = () => {
  // titleタグの設定
  usePageTitle(`ABOUT`);

  return (
    <div className={styles.about}>
      <Article title={<>What's Future Explorations</>}>
        <>
          <p className={styles.paragraph}>
            <strong className={styles.highlighter}>
              Future
              Explorationsは、100BANCHに集う若者たちによる300以上のプロジェクトから見えてきた未来の兆しを、より深く、より遠くへ広げていく未来社会のResearch
              &amp; Design活動です。
            </strong>
          </p>
          <p className={styles.paragraph}>100年先の世界は、どんな景色だろう。</p>
          <p className={styles.paragraph}>
            百（たくさん）のプロジェクトがうごめく実験区・100BANCH。
            <br />
            Future Explorationsは、100BANCHのGARAGE Programに潜む、多様な未来の兆し= &ldquo;Future
            Insight&rdquo;から見えてきた、100年後にありたい未来社会をさらに深堀りしてExplore（探索）するプロジェクトです。
            <br />
            GARAGE
            Programにおける、ジャンルを問わない多種多様なプロジェクトの実践活動と、それをリードする若者たちの意志（Will）に基づく「100年先に実現したい未来」を、より体系的に捉え、その「輪郭」をより際立たせることで、未来創造の活動を後押しし、100年先へと着実につなげていくための試みです。
          </p>
        </>
      </Article>
      <Article
        title={
          <>
            100年後の未来の
            <br aria-hidden="true" />
            13の当たり前に
            <br aria-hidden="true" />
            ついて
          </>
        }
      >
        <>
          <p className={styles.paragraph}>
            100BANCHによる未来社会のResearch &amp; Design活動の一環として、GARAGE
            Programに日々集まってくる未来への実験活動を俯瞰して見えてきた、100年後には当たり前になっている未来の光景を13の視点から描き出しました。
          </p>
          <p className={styles.paragraph}>
            GARAGE
            Programは、100年先につながる価値や文化の創造に取り組むU35の若者たちの活動を応援する100BANCHのコアプログラムです。常識にとらわれない野心的な若者たちが集まり、100BANCHで活動するプロジェクトは今や300を超えました。
          </p>
          <p className={styles.paragraph}>
            Future ExplorationsはこのGARAGE
            Programでの若者たちによる未来への実験活動を俯瞰し、彼らのつくりたい未来への”Will”たちの、共通パターンを見出したり、解釈を拡張したりしながら、100年後の未来の光景として紡いでいます。
          </p>
          <p className={styles.paragraph}>
            今の「常識」からは外れていたとしても、きっとそれが「未来の当たり前」になるのではないか。そんな思いとともに、この「100年後の未来の当たり前」のすがたの輪郭をよりはっきりと描き出そうとしています。
          </p>
        </>
      </Article>
      <div className={styles.banner}>
        <Link to="/futures" className={styles.bannerLink}>
          <span className={styles.bannerText}>
            <img src={txt_futures} alt="13の未来" />
          </span>
        </Link>
      </div>
    </div>
  );
};
