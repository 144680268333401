import { FC, useMemo } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

import styles from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
  addClass?: string[];
};
export const Breadcrumbs: FC<BreadcrumbsProps> = ({ addClass = [] }) => {
  const location = useLocation();

  // 状態管理からindexListを取得
  const { indexList } = useSelector((state: RootState) => state.sitemap);
  // 状態管理から今いるページのタイトルを取得
  const { currentPage } = useSelector((state: RootState) => state.user);

  // pathの最初の文字と一致するindexListのslugを探す
  const categoryName = useMemo(() => {
    const index = indexList.find(item => item.slug === location.pathname.split('/')[1]);
    return index ? index.label : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const breadcrumbsDom = useMemo(
    () => (
      <>
        <Link to="/">
          <svg
            className={styles.top}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 14"
            aria-labelledby="breadcrumbsRoot"
          >
            <desc id="breadcrumbsRoot">TOP</desc>
            <path d="M8,0L0,7.1h2.4V14h3.9V8.6h3.4V14h3.9V7.1H16L8,0z" />
          </svg>
        </Link>
        {location.pathname.replace(/\/$/g, '').split('/').length > 2 && (
          <>
            <svg className={styles.arrow} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
              <polyline fill="none" stroke="#aaa" points="1,0.757 5.243,5 1,9.243 " />
            </svg>
            <Link className={styles.link} to={`/${location.pathname.split('/')[1]}`}>
              {categoryName}
            </Link>
          </>
        )}
        <svg className={styles.arrow} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
          <polyline fill="none" stroke="#aaa" points="1,0.757 5.243,5 1,9.243 " />
        </svg>
        <Link className={styles.link} to={location.pathname}>
          {currentPage?.label}
        </Link>
      </>
    ),
    [categoryName, currentPage?.label, location.pathname]
  );

  // currentPageが存在し、currentPage.labelが"404"ではない場合はbreadcrumbsDomを返す
  return (
    <div className={[styles.breadcrumbs, ...addClass].join(' ')}>
      {currentPage && currentPage?.label !== '404' && breadcrumbsDom}
    </div>
  );
};
