import { FC } from 'react';

import styles from './LayoutCopyright.module.scss';

type LayoutCopyrightProps = {
  base?: 'dark' | 'light';
  addClass?: string[];
};

export const LayoutCopyright: FC<LayoutCopyrightProps> = ({ addClass = [], base = 'light' }) => {
  return (
    <div className={[styles.layoutCopyright, ...addClass, base && styles[`--${base}`]].join(' ')}>
      <small className={styles.small}>Copyright &copy; 100BANCH All Rights Reserved.</small>
    </div>
  );
};
