import { FC, ReactElement } from 'react';

import styles from './Headline.module.scss';

type HeadlineProps = {
  title: string | ReactElement;
  subTitle: string;
  addClass?: string[];
};

export const Headline: FC<HeadlineProps> = ({ title, subTitle, addClass = [] }) => {
  return (
    <header className={[styles.headline, ...addClass].join(' ')}>
      <h2 className={styles.title}>{title}</h2>
      <span className={styles.subTitle}>{subTitle}</span>
    </header>
  );
};
