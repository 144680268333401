import { FC, useMemo } from 'react';

import { IconExternalLink } from 'components/parts/IconExternalLink';

import styles from './UpdateItem.module.scss';

import type { UpdateIndexData } from 'types/update';

export const UpdateItem: FC<UpdateIndexData> = ({ title, date, type, link }) => {
  const label = useMemo(() => {
    switch (type as 'news' | 'events' | 'reports') {
      case 'events':
        return 'EVENT';
        break;
      case 'reports':
        return 'REPORT';
        break;
      case 'news':
        return 'NEWS';
        break;
      default:
        return 'UPDATE';
        break;
    }
  }, [type]);

  return (
    <a className={styles.updateItem} href={link} target="_blank" rel="noreferrer">
      <div className={styles.status}>
        <span className={styles.date}>{date.replace(/-/g, '.')}</span>
        <span className={styles.type}>{label}</span>
      </div>
      <h2 className={styles.title}>{title}</h2>
      <IconExternalLink addClass={[styles.iconExternalLink]} />
    </a>
  );
};
