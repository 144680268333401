import headText from './images/head-text.svg';
import styles from './MainVisual.module.scss';

export const MainVisual = () => {
  return (
    <div className={styles.mainVisual}>
      <div className={styles.bg}></div>
      <article className={styles.textWrap}>
        <header className={styles.head}>
          <h1>
            <img
              className={styles.titleTextImage}
              src={headText}
              alt="100年後の未来の13の当たり前"
            />
          </h1>
        </header>
        <div className={styles.body}>
          <p className={styles.paragraph}>
            ここでは、100年後の未来で当たり前となっている社会や人間のありようを、13の切り口から紹介したい。
            <br />
            構想の素になっているのは100BANCHのGARAGE Program
            に集まってきた多様な未来の実験活動、そして実験活動の原動力である未来へのWillたちである。
          </p>
          <p className={styles.paragraph}>
            「こうしたい」「こうありたい」という未来へのWillは、100年先への潮流を指摘する未来予測よりも強力な指針だ。
          </p>
          <p className={styles.paragraph}>
            そんな指針の示す方角や傾向を注意深く読み取って解釈し、時には創造的に拡張しながら概念化し表現したのが「100年後の未来の13の当たり前」である。
          </p>
        </div>
      </article>
    </div>
  );
};
