import { FC } from 'react';

import { Link } from 'react-router-dom';

import { usePageTitle } from 'hooks/usePageTitle';

import banner_narrow from './images/banner_narrow.webp';
import banner_wide from './images/banner_wide.webp';
import styles from './NotFound.module.scss';

export const NotFound: FC = () => {
  // titleタグの設定
  usePageTitle(`404`);

  return (
    <div className={styles.notfound}>
      <div className={styles.read}>
        <p className={styles.paragraph}>
          お探しの情報を見つけることはできませんでしたが、
          <br className={styles.showWide} />
          ここでは「100年後の未来の当たり前」の中にある幸せや持続可能性、美しさを重視する考え方に出会うことができます。
          <br className={styles.showWide} />
          一呼吸して、ぜひ寄り道してみてください。
        </p>
      </div>
      <div className={styles.banner}>
        <Link to="/" className={styles.link}>
          <picture>
            <source media="(max-width: 900px)" srcSet={banner_narrow} />
            <img
              src={banner_wide}
              alt="Future Explorations 未来を探しに行く GARAGE Programから見えてきた未来社会のすがた"
            />
          </picture>
        </Link>
      </div>
    </div>
  );
};
