import { FC } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

import styles from './LayoutPageTitle.module.scss';

type LayoutPageTitleProps = {
  title: string;
  copy: string;
  active?: boolean;
  invisible?: boolean;
  addClass?: string[];
};

export const LayoutPageTitle: FC<LayoutPageTitleProps> = ({
  title,
  copy,
  active = true,
  invisible = true,
  addClass = [],
}) => {
  const { changeColor } = useSelector((state: RootState) => state.user);

  return (
    <div
      className={[
        styles.layoutPageTitle,
        ...addClass,
        changeColor ? styles['--invisible'] : '',
      ].join(' ')}
      aria-hidden={!active}
    >
      <div className={styles.highlighter}>
        <h1 className={styles.title}>{title}</h1>
        <span className={styles.copy}>{copy}</span>
        <span className={styles.backdrop} data-invisible={invisible}></span>
      </div>
    </div>
  );
};
