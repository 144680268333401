import { FC, useRef } from 'react';

import { Link } from 'react-router-dom';

import { FuturesTitle } from 'components/parts/FuturesTitle';
import { PixelateEffect } from 'components/parts/PixelateEffect';

import useZeroPadding from 'hooks/useZeroPadding';

import styles from './IndexItem.module.scss';

import type { FuturesIndexItemData } from 'types/futures';
type IndexItemProps = FuturesIndexItemData & {
  addClass?: string[];
};
export const IndexItem: FC<IndexItemProps> = ({ no, keyword, body, addClass = [] }) => {
  // 0詰めした2桁の文字列を返す
  const zeroPaddingNum = useZeroPadding;

  const item = useRef<HTMLDivElement>(null);

  return (
    <div ref={item} className={[styles.futuresArticle, ...addClass].join(' ')}>
      <Link className={styles.link} to={'/futures/' + zeroPaddingNum(no)}>
        <figure className={styles.figure}>
          <PixelateEffect no={no} />
          <img className={styles.image} src={`/assets/futures/large/img_${no}.webp`} alt="" />
        </figure>
      </Link>
      <div className={styles.column}>
        <div className={styles.header}>
          <div className={styles.no}>
            <svg
              className={styles.noUnit}
              aria-labelledby={`no_unit-${no}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9.6 6.6"
            >
              <desc id={`no_unit-${no}`}>No</desc>
              <path
                d="M3.6,4.5L3.6,4.5L1.8,0H0v6.5h1.3V1.7h0l1.8,4.8h1.8V0H3.6V4.5z M7.8,1.9c-1.7,0-1.9,1.1-1.9,2.3
	c0,1.4,0.2,2.4,1.9,2.4s1.9-1,1.9-2.4C9.6,2.9,9.4,1.9,7.8,1.9z M7.8,5.8c-0.5,0-0.6-0.3-0.6-1.7c0-0.9,0-1.4,0.6-1.4
	c0.5,0,0.6,0.5,0.6,1.4C8.3,5.5,8.2,5.8,7.8,5.8z"
              />
            </svg>

            {zeroPaddingNum(no)}
          </div>
          <div className={styles.keyword}>
            <span className={styles.human} data-text="人">
              人
            </span>
            <span className={styles.corroborate}>✕</span>
            {keyword}
          </div>
        </div>
        <FuturesTitle body={body} rootMargin={'-31%'} />
      </div>
    </div>
  );
};
