import { FC } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { IconExternalLink } from 'components/parts/IconExternalLink';
import { RootState } from 'store';

import styles from './CategoryNavigation.module.scss';

type CategoryNavigationProps = {
  close: () => void;
  minimum?: boolean;
  base?: 'dark' | 'light';
};

export const CategoryNavigation: FC<CategoryNavigationProps> = ({
  close,
  minimum,
  base = 'light',
}) => {
  const location = useLocation();

  // 状態管理からindexListを取得
  const { indexList } = useSelector((state: RootState) => state.sitemap);

  return (
    <ul
      className={[
        styles.categoryNavigation,
        base && styles[`--${base}`],
        minimum && styles['--minimum'],
      ].join(' ')}
    >
      {indexList
        .filter(item => item.isPrimary)
        .map(item => (
          <li key={item.slug} className={styles.item}>
            <Link
              className={styles.link}
              to={item.slug === 'home' ? '/' : `/${item.slug}`}
              onClick={close}
              aria-current={location.pathname.startsWith(`/${item.slug}`) ? 'page' : undefined}
            >
              {item.label}
            </Link>
          </li>
        ))}
      <li className={styles.item}>
        <a
          className={styles.link}
          href="https://share.hsforms.com/1YOle0cCTSv6i1Ygs60y2VAblv72?__hstc=29206499.1de00c247883ee51c314c4286915b97b.1668045377180.1668045377180.1668045377180.1&__hssc=29206499.2.1668045377180&__hsfp=965468661"
          target="_blank"
          rel="noreferrer"
          onClick={close}
        >
          CONTACT
          <IconExternalLink addClass={[styles.iconExternalLink]} />
        </a>
      </li>
    </ul>
  );
};
