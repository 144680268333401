import { forwardRef } from 'react';

import styles from './WebSiteName.module.scss';

import { Copy } from './components/Copy';
import { TitleLogo } from './components/TitleLogo';

type WebSiteNameProps = {
  base?: 'dark' | 'light';
  addClass?: string[];
};

export const WebSiteName = forwardRef<HTMLDivElement, WebSiteNameProps>(
  ({ base = 'light', addClass = [] }, ref) => {
    return (
      <div className={[styles.websiteName, ...addClass].join(' ')} ref={ref}>
        <TitleLogo addClass={[styles.title]} base={base} />
        <Copy addClass={[styles.copy]} base={base} />
      </div>
    );
  }
);
