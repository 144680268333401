import { FC } from 'react';

import styles from './SignalItem.module.scss';

type SignalItemProps = {
  image: string;
  title: string;
  link: string;
};

export const SignalItem: FC<SignalItemProps> = ({ image, title, link }) => {
  return (
    <a className={styles.signalItem} href={link}>
      <img className={styles.image} src={image} alt={title} />
    </a>
  );
};
