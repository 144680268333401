import { FC } from 'react';

import styles from './ImageSwitcher.module.scss';

type ImageSwitcherProps = {
  addClass?: string[];
};
export const ImageSwitcher: FC<ImageSwitcherProps> = ({ addClass = [] }) => {
  // 1から13までの数値を子要素としたnumber[]配列を生成
  const futuresNumber = [...Array(25)].map((_, i) => i + 1);

  return (
    <div className={[styles.imageSwitcher, ...addClass].join(' ')}>
      {futuresNumber.map((no, index) => (
        <div key={no} className={styles.item} style={{ animationDelay: `-${index * 0.1}s` }}>
          <img src={require(`components/parts/ImageSwitcher/images/img_${no}.webp`)} alt="" />
        </div>
      ))}
    </div>
  );
};
