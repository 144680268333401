import { FC } from 'react';

import styles from './Introduction.module.scss';

type IntroductionProps = {
  image: string;
  title: string;
  introductionTitle?: string;
  description: string[];
  addClass?: string[];
};

export const Introduction: FC<IntroductionProps> = ({
  image,
  title,
  introductionTitle,
  description,
  addClass = [],
}) => {
  return (
    <header className={[styles.introduction, ...addClass].join(' ')}>
      <div className={styles.mainVisual}>
        <figure className={styles.background}>
          <img className={styles.backgroundImage} src={image} alt="" />
        </figure>
        <figure className={styles.figure}>
          <img className={styles.image} src={image} alt="" />
        </figure>
      </div>
      <article className={styles.body}>
        <header>
          <h1 className={styles.title}>{title}</h1>
          <h2 className={styles.introductionTitle}>
            <span className={styles.introductionTitleText}>{introductionTitle}</span>
          </h2>
        </header>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description.join('<br />') }}
        ></div>
      </article>
    </header>
  );
};
