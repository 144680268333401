import { FC, useEffect, useState, useMemo, useRef } from 'react';

import { Headline } from '../Headline';
import { MagazineItem } from '../MagazineItem';

import styles from './Magazine.module.scss';

import { useMagazine } from './useMagazine';

import type { FuturesMagazineData } from 'types/futures';

type MagazineProps = {
  id: number;
  addClass?: string[];
};
export const Magazine: FC<MagazineProps> = ({ id, addClass = [] }) => {
  const { list } = useMagazine(id);
  return list.length ? <MagazineView list={list} addClass={addClass} /> : <></>;
};

type MagazineViewProps = {
  list: FuturesMagazineData[];
  addClass?: string[];
};
export const MagazineView: FC<MagazineViewProps> = ({ list, addClass = [] }) => {
  const [isIosSafari, setIsIosSafari] = useState(false);

  // iOS Safariはスクロールバーへスタイルシートを設定できないため分岐させる
  useEffect(() => {
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsIosSafari(iOS && safari);
  }, []);

  // スクロール制御
  const body = useRef<HTMLDivElement>(null);

  // bodyのスクロール位置
  const [scrollX, setScrollX] = useState<number | null>(null);

  // bodyのスクロール位置を取得
  useEffect(() => {
    const parent = body.current;
    if (!parent) return;
    setScrollX(parent.scrollLeft);
    const getPosition = () => {
      setScrollX(parent.scrollLeft);
    };
    parent.addEventListener('scroll', getPosition, false);
    return () => {
      parent.removeEventListener('scroll', getPosition, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // bodyのスクロール位置が先頭
  const isStart = useMemo(() => {
    if (body.current) return scrollX === 0;
    return true;
  }, [scrollX]);

  // bodyのスクロール位置が最後尾
  const isEnd = useMemo(() => {
    if (body.current) return scrollX === body.current.scrollWidth - body.current.clientWidth;
    return true;
  }, [scrollX]);

  const scrollAnimation = (direction: number) => {
    const target = body.current;
    if (!target) return;
    const card = target.children[0];
    if (!card) return;
    const cardWidth = card.clientWidth;
    const start = target.scrollLeft;

    target.scrollLeft = start + cardWidth * direction;
  };

  // previewボタン
  const handlePreview = () => {
    if (body.current) {
      scrollAnimation(-1);
    }
  };

  // nextボタン
  const handleNext = () => {
    if (body.current) {
      scrollAnimation(1);
    }
  };

  return (
    <article className={[styles.magazine, ...addClass].join(' ')}>
      <Headline
        title={
          <>
            100BANCH
            <br /> MAGAZINE
          </>
        }
        subTitle="マガジン"
        addClass={[styles.headline]}
      />
      <div className={styles.console}>
        <button
          type="button"
          className={[styles.button, styles['--preview']].join(' ')}
          disabled={isStart}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            aria-labelledby="scroscrollller-preview"
            onClick={handlePreview}
          >
            <desc id="scroll-preview">preview</desc>
            <path
              fill="#ffffff"
              d="M36.1,40l0.1-0.1v-2.5l-2.3,1.3L36.1,40z M32.1,37.6l4.1-2.4v-1.4l-5.3,3.1L32.1,37.6z M28.9,35.8l7.3-4.2V29
              l-9.5,5.5L28.9,35.8z M23.7,32.7l1.2,0.7l11.3-6.6v-1.4L23.7,32.7z"
            />
          </svg>
        </button>
        <button
          type="button"
          className={[styles.button, styles['--next']].join(' ')}
          disabled={isEnd}
          onClick={handleNext}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="scroll-next">
            <desc id="scroll-next">next</desc>
            <path
              fill="#ffffff"
              d="M30.1,38.7l-2.3-1.3v2.5l0.1,0.1L30.1,38.7z M33.1,36.9l-5.3-3.1v1.4l4.1,2.4L33.1,36.9z M37.3,34.5L27.8,29
              v2.5l7.3,4.2L37.3,34.5z M27.8,25.4v1.4l11.3,6.6l1.2-0.7L27.8,25.4z"
            />
          </svg>
        </button>
      </div>
      <div
        className={[styles.list, isIosSafari ? styles['--isIosSafari'] : ''].join(' ')}
        dir="ltr"
        ref={body}
      >
        {list.map(item => (
          <MagazineItem key={item.id} {...item} />
        ))}
      </div>
    </article>
  );
};
