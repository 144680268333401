import { FC } from 'react';

import { FuturesTitle } from 'components/parts/FuturesTitle';

import useZeroPadding from 'hooks/useZeroPadding';

import styles from './Introduction.module.scss';

type IntroductionProps = {
  id: number;
  keyword: string;
  title: string;
  body: string;
  addClass?: string[];
};

export const Introduction: FC<IntroductionProps> = ({
  id,
  keyword,
  title,
  body,
  addClass = [],
}) => {
  const zeroPadding = useZeroPadding;
  return (
    <div className={[styles.introduction, ...addClass].join(' ')}>
      <div className={styles.header}>
        <div className={styles.layout}>
          <div className={styles.no}>
            <svg
              className={styles.noUnit}
              aria-labelledby={`no_unit-${id}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9.6 6.6"
            >
              <desc id={`no_unit-${id}`}>No</desc>
              <path
                d="M3.6,4.5L3.6,4.5L1.8,0H0v6.5h1.3V1.7h0l1.8,4.8h1.8V0H3.6V4.5z M7.8,1.9c-1.7,0-1.9,1.1-1.9,2.3
c0,1.4,0.2,2.4,1.9,2.4s1.9-1,1.9-2.4C9.6,2.9,9.4,1.9,7.8,1.9z M7.8,5.8c-0.5,0-0.6-0.3-0.6-1.7c0-0.9,0-1.4,0.6-1.4
c0.5,0,0.6,0.5,0.6,1.4C8.3,5.5,8.2,5.8,7.8,5.8z"
              />
            </svg>
            {zeroPadding(id)}
          </div>
          <div className={styles.keyword}>
            <span className={styles.human} role="img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 65 60"
                className={styles.textImage}
                aria-labelledby="human-label"
                role="img"
              >
                <desc id="human-label">人</desc>
                <path
                  d="M35.8,0.6h-8c0,0.5,0,1.1-0.1,1.7C27.6,5,27.5,8.8,26.9,13c-0.6,4.8-1.6,9.4-3.1,14
                  c-4,11.6-11.2,20.5-21.4,26.4c2.2,1.6,4.1,3.4,5.8,5.5c6.2-3.8,11.5-8.9,15.5-14.9c3.6-5.4,6.4-11.4,8.3-17.7l0.5-1.6l0.5,1.6
                  c1.9,6.5,4.8,12.6,8.7,18.2c4.3,6.1,10,11,16.6,14.4c1.5-2.2,3.3-4.2,5.3-5.9c-5.4-2.7-10.3-6.4-14.2-11c-3.6-4.3-6.5-9.1-8.6-14.3
                  c-1.8-4.5-3.1-9.1-4-13.8c-0.6-3.3-1-6.6-1.2-10l0,0l0,0C35.6,2.8,35.7,1.6,35.8,0.6z"
                  fill="none"
                  stroke="currentColor"
                />
              </svg>
            </span>
            <span className={styles.corroborate}>✕</span>
            {keyword}
          </div>
        </div>
        <FuturesTitle addClass={[styles.title]} body={title} />
      </div>
      <div className={styles.paragraph}>{body}</div>
    </div>
  );
};
