import { FC, useMemo, useEffect, useState } from 'react';

import { useParams, useLocation, Navigate } from 'react-router-dom';

import { createClient } from 'microcms-js-sdk';

import projectLogSourceList from 'data/sources/projectLog.json';

import { usePageTitle } from 'hooks/usePageTitle';

import { Collaborates } from '../ProjectDetail/components/Collaborates';
import { Introduction } from '../ProjectDetail/components/Introduction';
import { ProjectLog } from '../ProjectDetail/components/ProjectLog';
import { RelationFutures } from '../ProjectDetail/components/RelationFutures';
import styles from '../ProjectDetail/ProjectDetail.module.scss';

import type { ProjectPreviewData, ProjectLogSource } from 'types/project';

export const ProjectPreview: FC = () => {
  // パラメータを取得
  // URLパラメータから開始ページを取得
  const { slug } = useParams<string>();
  const query = new URLSearchParams(useLocation().search);
  const draftKey = query.get('draftKey') || '';

  const [currentArticleSource, setCurrentArticleSource] = useState<
    ProjectPreviewData | 'error' | null
  >(null);

  useEffect(() => {
    const client = createClient({
      serviceDomain: process.env.REACT_APP_MICROCMS_SERVICE ?? '',
      apiKey: process.env.REACT_APP_MICROCMS_KEY ?? '',
    });

    if (slug && draftKey)
      client
        .get({
          endpoint: 'project',
          contentId: slug,
          queries: { draftKey },
        })
        .then(res => setCurrentArticleSource(res))
        .catch(() => setCurrentArticleSource('error'));
  }, [slug, draftKey]);

  const currentArticle = useMemo(() => {
    if (currentArticleSource === null || currentArticleSource === 'error') return null;

    // descriptionの情報を整形
    const convertedArray = (textarea: string) => textarea.split('\n');
    const description = currentArticleSource.description
      ? convertedArray(currentArticleSource.description)
      : [];

    // コラボレーターズの情報を整形
    const collaborates = currentArticleSource.collaborates.map((collaborate, index) => {
      // 関連リンクの情報を整形
      const relation = collaborate.relation
        ? collaborate.relation.map(item => {
            const label = item.label ? { label: item.label } : {};
            return { ...label, url: item.url };
          })
        : [];

      return {
        name: collaborate.name,
        position: collaborate.position,
        body: collaborate.body,
        image: collaborate.image.url,
        relation,
      };
    });

    // プロジェクトの歩み情報を整形
    let projectLogSource: ProjectLogSource[] = [];
    if (currentArticleSource.slug in projectLogSourceList) {
      projectLogSource = [
        ...projectLogSourceList[currentArticleSource.slug as keyof typeof projectLogSourceList],
      ];
    }
    const projectLog = projectLogSource.map(item => {
      const { id, title, date, post_type, thumb, link } = item;
      return { id, title, date, type: post_type, image: thumb, link };
    });

    const result = {
      id: currentArticleSource.slug,
      title: currentArticleSource.title,
      image: currentArticleSource.image.url,
      introductionTitle: currentArticleSource.introductionTitle,
      description,
      futures: currentArticleSource.futures.map(future => future.no),
      collaborates,
      projectLog,
      body: currentArticleSource.body,
    };
    console.log({ result });

    return result;
  }, [currentArticleSource]);

  // titleタグの設定
  usePageTitle(currentArticle ? currentArticle.title : '');

  return currentArticleSource === null ? ( // 記事のリクエスト中
    <></>
  ) : currentArticle === null ? ( // slugと紐づく記事がない場合は404ページへリダイレクト
    <Navigate replace to="/404" />
  ) : (
    <article className={styles.projectDetail}>
      <div className={styles.stripeBackground}></div>
      <Introduction
        image={currentArticle.image}
        title={currentArticle.title}
        introductionTitle={currentArticle.introductionTitle}
        description={currentArticle.description}
      />
      <div className={styles.body}>
        <RelationFutures futures={currentArticle.futures} />

        {currentArticle.collaborates && <Collaborates list={currentArticle.collaborates || []} />}

        <ProjectLog data={currentArticle.projectLog} title={currentArticle.title} />

        {currentArticle.body && (
          <div
            className={styles.free}
            dangerouslySetInnerHTML={{ __html: currentArticle.body }}
          ></div>
        )}
      </div>
    </article>
  );
};
