import { FC, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { CloseUpCursor } from 'components/parts/CloseUpCursor';
import { RootState } from 'store';

import { usePageTitle } from 'hooks/usePageTitle';

import styles from './Futures.module.scss';

import { CopyArea } from './components/CopyArea';
import { IndexItem } from './components/IndexItem';
import { MainVisual } from './components/MainVisual';

export const Futures: FC = () => {
  // 13の未来一覧のデータを取得
  const { indexList } = useSelector((state: RootState) => state.futures);

  // マウスストーカー
  const parent = useRef<HTMLElement>(null);
  const cursor = useRef<HTMLDivElement>(null);
  const requestRef = useRef<number>();

  useEffect(() => {
    const area = parent.current;
    const target = cursor.current;

    const onMouseMove = (event: MouseEvent) => {
      const target = cursor.current;
      if (target && area) {
        // requestAnimationFrameでアニメーション再描画を管理
        requestRef.current = requestAnimationFrame(() => {
          target.style.setProperty('left', `${event.clientX}px`);
          target.style.setProperty('top', `${event.clientY}px`); // yは親要素の位置も加味する
        });
      }
    };

    // targetをマウスカーソルへ追従させる
    if (area && target) area.addEventListener('mousemove', onMouseMove);

    return () => {
      // イベントリスナーを削除
      area?.removeEventListener('mousemove', onMouseMove);

      // requestAnimationFrameをキャンセル
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [cursor]);

  usePageTitle(`13の未来の当たり前`);

  return (
    <>
      <main className={styles.futures} ref={parent}>
        <header>
          <MainVisual />
        </header>
        <div className={styles.body}>
          {indexList.map(item => (
            <IndexItem key={item.no} {...item} />
          ))}
        </div>
        <CopyArea />
        {/* マウスカーソルが画面の端の位置にいるときストーカーサイズ分の横スクロールが発生しないようoverflow:hiddenするためのスペースで囲む */}
        <div className={styles.cursorArea}>
          <CloseUpCursor ref={cursor} />
        </div>
      </main>
    </>
  );
};
