import { FC } from 'react';

import styles from './Date.module.scss';

type DateProps = {
  date: string;
  addClass?: string[];
};

export const Date: FC<DateProps> = ({ date, addClass = [] }) => {
  const day = date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3');
  const monthYear = date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1.$2');
  return (
    <div className={[styles.date, ...addClass].join(' ')}>
      <span className={styles.monthYear}>{monthYear}</span>
      <span className={styles.day} data-day={day}>
        {day}
      </span>
    </div>
  );
};
