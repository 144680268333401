import { createSlice } from '@reduxjs/toolkit';

import futuresIndexData from 'data/futuresIndex.json';
import magazine from 'data/magazine.json';
import signal from 'data/signal.json';
import vipSignal from 'data/signalVipTable.json';

import type {
  FuturesIndexItemData,
  FuturesSignalData,
  FuturesVipSignalData,
  FuturesMagazineData,
} from 'types/futures';

type State = {
  indexList: FuturesIndexItemData[];
  signal: FuturesSignalData[];
  magazine: FuturesMagazineData[];
  vipSignal: FuturesVipSignalData[];
};

const initialState: State = {
  indexList: futuresIndexData,
  signal,
  magazine,
  vipSignal,
};

const futures = createSlice({
  name: 'futures',

  initialState,

  reducers: {},
});

// Action Creator
// export const {} = futures.actions;

// Reducer
export default futures.reducer;
