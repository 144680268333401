import { FC } from 'react';

import { Date } from 'components/parts/Date';

import styles from './EventItem.module.scss';

import type { EventIndexData } from 'types/event';

export const EventItem: FC<EventIndexData> = ({
  image,
  title,
  description,
  project,
  date,
  link,
}) => {
  return (
    <a className={styles.eventItem} href={link}>
      <figure className={styles.figure}>
        <img src={image || `/assets/no-image.webp`} alt="" className={styles.image} />
      </figure>
      <div>
        <div className={styles.header}>
          <Date date={date} addClass={[styles.date]} />
          <div className={styles.project}>
            {project.map(tag => (
              <span key={tag.id} className={styles.tag}>
                {tag.label}
              </span>
            ))}
          </div>
        </div>
        <strong className={styles.title}>{title}</strong>
        <p className={styles.description}>{description}</p>
      </div>
    </a>
  );
};
