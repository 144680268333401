import { FC, useCallback } from 'react';

import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

import { useToPlainText } from 'hooks/useToPlainText';
import useZeroPadding from 'hooks/useZeroPadding';

import styles from './RelationFutures.module.scss';

type RelationFuturesProps = {
  futures: number[];
  addClass?: string[];
};

export const RelationFutures: FC<RelationFuturesProps> = ({ futures, addClass = [] }) => {
  // 13の未来一覧のデータを取得
  const { indexList } = useSelector((state: RootState) => state.futures);

  // タグを取り除く
  const toPlainText = useToPlainText;

  // 0詰めした2桁の文字列を返す
  const zeroPadding = useZeroPadding;

  const item = useCallback(
    (no: number) => {
      const currentTitle = indexList.find(index => index.no === no) || indexList[0];
      return (
        <li key={no} className={styles.item}>
          <div className={styles.no}>
            <svg
              className={styles.noUnit}
              aria-labelledby={`no_unit-${no}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9.6 6.6"
            >
              <desc id={`no_unit-${no}`}>No</desc>
              <path
                d="M3.6,4.5L3.6,4.5L1.8,0H0v6.5h1.3V1.7h0l1.8,4.8h1.8V0H3.6V4.5z M7.8,1.9c-1.7,0-1.9,1.1-1.9,2.3
c0,1.4,0.2,2.4,1.9,2.4s1.9-1,1.9-2.4C9.6,2.9,9.4,1.9,7.8,1.9z M7.8,5.8c-0.5,0-0.6-0.3-0.6-1.7c0-0.9,0-1.4,0.6-1.4
c0.5,0,0.6,0.5,0.6,1.4C8.3,5.5,8.2,5.8,7.8,5.8z"
              />
            </svg>
            {zeroPadding(no)}
          </div>
          <Link to={`/futures/${no}`} className={styles.label}>
            <span className={styles.labelDecorationInline}>
              {toPlainText(<div dangerouslySetInnerHTML={{ __html: currentTitle.body }}></div>)}
            </span>
          </Link>
        </li>
      );
    },
    [indexList, toPlainText, zeroPadding]
  );

  return (
    <article className={styles.relationFutures}>
      <header>
        <h2 className={styles.title}>
          13の未来の
          <br className={styles.layoutBr} aria-hidden="true" />
          当たり前
        </h2>
      </header>
      <ul className={styles.body}>{futures.map(no => item(no))}</ul>
    </article>
  );
};
