import { FC } from 'react';

import { Link } from 'react-router-dom';

import { ImageSwitcher } from 'components/parts/ImageSwitcher';
import { OrganizationLogo } from 'components/parts/OrganizationLogo';
import { WebSiteName } from 'components/parts/WebSiteName';

import styles from './LayoutHeader.module.scss';

type LayoutHeaderProps = {
  base?: 'dark' | 'light';
  addClass?: string[];
};

export const LayoutHeader: FC<LayoutHeaderProps> = ({ base = 'light', addClass = [] }) => {
  return (
    <div className={[styles.layoutHeader, ...addClass, base && styles[`--${base}`]].join(' ')}>
      <OrganizationLogo addClass={[styles.organization]} base={base} />
      <Link to="/" className={styles.link}>
        <ImageSwitcher addClass={[styles.imageSwitcher]} />
        <WebSiteName addClass={[styles.webSiteName]} base={base} />
      </Link>
    </div>
  );
};
