import { FC } from 'react';

import styles from './LocalizedTagline.module.scss';

type LocalizedTaglineProps = {
  color?: 'black' | 'white';
};

export const LocalizedTagline: FC<LocalizedTaglineProps> = ({ color }) => {
  return (
    <div className={[styles.localizedTagline, color && styles[`--${color}`]].join(' ')}>
      100年後の未来の13の当たり前
      <span className={styles.text}></span>
      <span className={styles.accent}></span>
    </div>
  );
};
