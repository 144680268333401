import { forwardRef } from 'react';

import styles from './OrganizationLogo.module.scss';

type OrganizationLogoProps = {
  base?: 'dark' | 'light';
  addClass?: string[];
};

export const OrganizationLogo = forwardRef<HTMLAnchorElement, OrganizationLogoProps>(
  ({ base = 'light', addClass = [] }, ref) => {
    return (
      // 派生元のオフィシャルサイト（所有者が同一かつ関連が強いコンテンツ）へのリンクなので noreferrer noopener していない
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        className={[styles.organizationLogo, ...addClass, styles[`--${base}`]].join(' ')}
        href="https://100banch.com/"
        target="_blank"
        ref={ref}
      >
        <span className={styles.imageArea}>100BANCH</span>
      </a>
    );
  }
);
