import { FC } from 'react';

import styles from './TitleLogo.module.scss';

type TitleLogoProps = {
  base?: 'dark' | 'light';
  navigation?: boolean;
  addClass?: string[];
};

export const TitleLogo: FC<TitleLogoProps> = ({ base = 'light', addClass = [] }) => {
  return (
    <div className={[styles.titleLogo, ...addClass, base && styles[`--${base}`]].join(' ')}>
      <div className={styles.bg}>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
      <svg
        className={styles.image}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 560 134"
        role="img"
        aria-labelledby="websiteTitle"
      >
        <title id="websiteTitle">FUTURE EXPLORATIONS</title>
        <path
          fill="#ffffff"
          d="M301.5,34.5h73V30h-73V34.5z M164.7,77H154v51.1h26.7v-8.5h-16V77z M125.5,77h-19.2v51.1H117v-20.8
	h7.9c12.5,0,14.2-9.2,14.2-15C139.1,83,135.2,77,125.5,77z M122.9,99.5H117V84.9h5.4c4.8,0,5.9,3.5,5.9,7.7
	C128.2,96,126,99.5,122.9,99.5z M174,41.8c0,5.7-2.1,8.8-6.8,8.8c-3.9,0-6.6-2.5-6.6-8.8V6.3H150v36.2c0,9.6,5.7,15.7,17.4,15.7
	c13,0,17.3-8.1,17.3-15.7V6.3H174V41.8z M71.8,58.2c13,0,17.3-8.1,17.3-15.7V6.3H78.5v35.5c0,5.7-2.1,8.8-6.8,8.8
	c-3.9,0-6.6-2.5-6.6-8.8V6.3H54.4v36.2C54.4,52.1,60.1,58.2,71.8,58.2z M25.2,35.1h15.1v-7.9H25.2V14.1H41V6.3H14.5v51.1h10.7V35.1z
	 M25.2,105.3H41v-7.9H25.2V84.9h17.2V77H14.5v51.1h28.4v-7.9H25.2V105.3z M92.7,77H81.6l-7.1,16.8L67.2,77H55l12.3,24.3L54.1,128h11
	l7.8-17.6l7.9,17.6H93l-13-25.7L92.7,77z M114.2,57.4h10.7V14.8h11.9V6.3h-34.4v8.5h11.9L114.2,57.4L114.2,57.4z M264.6,103.1
	L264.6,103.1c6.9-1.2,9.5-6.1,9.5-12.6c0-8.7-4.7-13.4-12-13.4h-19.7v51.1h10.7v-21.1h2.8c8.2,0,7.6,5.2,7.6,11.2
	c0,3.3-0.3,6.8,1.1,9.9h10.5c-1-2.1-1.2-11.7-1.2-15C273.9,103.9,266.9,103.3,264.6,103.1z M257.8,99.1h-4.7V84.9h4.7
	c3.4,0,5.4,1.8,5.4,6.7C263.2,94.9,262,99.1,257.8,99.1z M427,76.2c-17.6,0-18.6,12.9-18.6,26.4c0,13.4,0,26.4,17.6,26.4l0,0
	c17.6,0,17.6-13,17.6-26.4C443.6,89.1,444.6,76.2,427,76.2z M426,121.3L426,121.3c-6,0-6.8-5.7-6.8-18.7s0.8-18.7,6.8-18.7
	s6.8,5.7,6.8,18.7S432,121.3,426,121.3z M211,76.2c-17.6,0-18.6,12.9-18.6,26.4c0,13.4,0,26.4,17.6,26.4s17.6-13,17.6-26.4
	C227.6,89.1,228.6,76.2,211,76.2z M210,121.3L210,121.3c-6,0-6.8-5.7-6.8-18.7s0.8-18.7,6.8-18.7s6.8,5.7,6.8,18.7
	S216,121.3,210,121.3z M524.4,89.3c0-3.5,2.1-5.4,5.5-5.4c4.3,0,5,3.9,5,7.7h10.4c0.7-10.7-3.5-15.3-13.9-15.3
	c-13,0-17.8,6.4-17.8,14.4c0,15.2,21.5,15.8,21.5,25c0,3.5-1.8,5.8-5.4,5.8c-5.9,0-6.1-4.1-6.1-9H513c-0.6,8.8,1.9,16.6,15.1,16.6
	c8.1,0,17.8-1.6,17.8-14.8l0,0C545.9,98.1,524.4,99,524.4,89.3z M381.7,128.1h10.7V77h-10.7V128.1z M486.8,112.4h-0.1L473.1,77
	h-14.4v51.1h10V90.8h0.1l13.9,37.3h14.1V77h-10L486.8,112.4L486.8,112.4z M333.8,85.5h11.9v42.6h10.7V85.5h11.9V77h-34.4v8.5H333.8z
	 M260.4,34.6h15.8v-7.9h-15.8V14.1h17.2V6.3h-27.9v51.1h28.4v-7.9h-17.8L260.4,34.6L260.4,34.6z M223.9,32.3L223.9,32.3
	c6.9-1.2,9.5-6.1,9.5-12.6c0-8.7-4.7-13.4-12-13.4h-19.7v51.1h10.7V36.2h2.8c8.2,0,7.6,5.2,7.6,11.2c0,3.3-0.3,6.8,1.1,9.9h10.5
	c-1-2.1-1.2-11.7-1.2-15C233.1,33.2,226.2,32.6,223.9,32.3z M217.1,28.4h-4.7V14.1h4.7c3.4,0,5.4,1.8,5.4,6.7
	C222.5,24.1,221.3,28.4,217.1,28.4z M300.7,77l-14.1,51.1h10.5l2.9-11.3h14.6l2.8,11.3h11.2L314.7,77H300.7z M302.1,108.9l4.9-22.1
	h0.1l5.2,22.1H302.1z"
        />
      </svg>
    </div>
  );
};
