import { useSelector } from 'react-redux';

import { RootState } from 'store';

import type { ProjectData } from 'types/project';

export const useProject = (id: number) => {
  const allList = useSelector((state: RootState) => state.project.list);

  const list: ProjectData[] = id ? allList.filter(article => article.futures.includes(id)) : [];
  return { list };
};
