import { useSelector } from 'react-redux';

import { RootState } from 'store';

import type { EventIndexData } from 'types/event';
export const useEvent = (id: number) => {
  const allList = useSelector((state: RootState) => state.event.list);

  const list: EventIndexData[] = id ? allList.filter(article => article.futures.includes(id)) : [];
  return { list };
};
