import type { PageTypeData } from 'types/artwork';
/**
 * PageType
 * @document URLからページタイプを判定します
 * @returns PageTypeData
 */

export const usePageType = (): PageTypeData => {
  // 現在のURLを取得
  const pathname = new URL(window.location.href).pathname;

  // ページタイプを判定
  const type: PageTypeData =
    pathname === '/'
      ? 'index' // ルートの場合は`index`
      : pathname.match(/^\/futures\//g) && pathname.match(/\d$/g)
      ? 'future' // futures下層コンテンツの場合は`future`
      : 'other'; // その他は`other`

  return type;
};
