import { createSlice } from '@reduxjs/toolkit';

import list from 'data/event.json';

import type { EventIndexData } from 'types/event';
type State = {
  list: EventIndexData[];
};

const initialState: State = {
  list,
};

const event = createSlice({
  name: 'event',

  initialState,

  reducers: {},
});

// Action Creator
// export const {} = event.actions;

// Reducer
export default event.reducer;
