import { FC, useEffect, useRef, useMemo } from 'react';

import { useLocation, useOutlet } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { LayoutCopyright } from 'components/layout/LayoutCopyright';
import { LayoutFooter } from 'components/layout/LayoutFooter';
import { LayoutNavigation } from 'components/layout/LayoutNavigation';
import { LayoutPageTitle } from 'components/layout/LayoutPageTitle';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { RootState } from 'store';
import { updateIsWritingContentData } from 'store/sitemap';
import {
  updatePreviousPage,
  updateChangeColor,
  updateDefaultTransition,
  updateFutureTransition,
  updateBaseColor,
} from 'store/user';

import { usePageType } from 'hooks/usePageType';

import styles from './Layout.module.scss';

/**
 * Layout
 * @description レイアウトコンポーネント。WEB GLのトランジションと連動してコンテンツ可視状態の切り替え制御も行っている。
 * @returns {ReactNode}  - Layout
 */
export const Layout: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentOutlet = useOutlet();
  const body = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  // 初回表示済フラグ
  const {
    initialize,
    previousPage,
    currentPage,
    baseColor,
    changeColor,
    defaultTransition,
    futureTransition,
  } = useSelector((state: RootState) => state.user);
  const { isWritingContentData } = useSelector((state: RootState) => state.sitemap);

  // URLからページの種類を判定
  const getPageType = usePageType;

  // 何ミリ秒かけてコンテンツの切り替えアニメーションを再生するか
  const animationDuration = 250;

  // 遷移元コンテンツによってアニメーションの挙動を分岐させるため data-from 付与
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fromType = useMemo(() => previousPage?.type, [location.pathname]);

  // ページの種類を判定
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentPageType = useMemo(() => getPageType(), [location.pathname]);

  // トランジション有無の判定
  const noTransition = useMemo(() => {
    let result = false;
    // 同じページどうしはトランジションしない
    if (previousPage?.pathname === location.pathname) result = true;
    // otherからotherへ回遊の遷移をしたときはartworkGLのトランジションが発生しない
    if (previousPage?.type === 'other' && currentPageType === 'other') result = true;
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // pageTitleコンポーネントの可視化フラグ
  const hasPageTitle = useMemo(() => {
    const result =
      currentPageType === 'other' &&
      !location.pathname.match(/^\/project-preview\/(.*)$/i) &&
      !location.pathname.match(/^\/project\/(.*)$/i) &&
      !location.pathname.match(`/futures(/)?`);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // 本文の可視化フラグ
  const visibleContents = useMemo(() => {
    let result = true;
    const isIndex = location.pathname === '/';

    // TOPではない
    if (!isIndex) {
      // 背景色変化中か、通常トランジションか、TOP→13の未来詳細トランジションが実行中は本文を非表示にする
      if (changeColor || defaultTransition || futureTransition) result = false;
    }
    return result;
  }, [location.pathname, changeColor, defaultTransition, futureTransition]);

  // ページ切替処理中フラグ: 表示開始アニメーションの再生合図に使用
  useEffect(() => {
    // location変更時に立てたフラグをanimationDuration経過後に解除: 表示中ページへのSPA遷移を実行してもフラグを回収できるようにする対策
    const timer = setTimeout(() => {
      if (isWritingContentData) dispatch(updateIsWritingContentData(false));
      clearTimeout(timer);
    }, animationDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWritingContentData]);

  // ナビのベースカラー設定: location変更時
  useEffect(() => {
    // TOP(=ベースカラーdark)かどうか判定
    const isDark = location.pathname === '/';
    if (isDark) {
      // TOP
      dispatch(updateBaseColor('dark')); // darkにする
      dispatch(updateChangeColor(false)); // 即座に色変更完了
    } else if (baseColor !== 'light') {
      // 現在のベースカラーがlightでない場合だけ実行
      dispatch(updateBaseColor('light')); // lightにする
      dispatch(updateChangeColor(true)); // 色変更中フラグを立てる
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // ルーティングの切替で実行する処理
  useEffect(() => {
    // ページ切替処理中フラグを立てる
    if (initialize) dispatch(updateIsWritingContentData(true));

    // 通常トランジション開始
    // アクセス開始表示ではなくartworkGLのトランジションが発生する遷移である場合
    if (initialize && !noTransition) dispatch(updateDefaultTransition(true));

    // ページ種別に応じたWEB GLのトランジションを開始
    // ただし、初回表示時は実行しないのでinitializeフラグを確認
    if (initialize) {
      if (currentPageType === 'index') {
        window.artworkGL.emit('UPDATE_TRANSITION', {
          nextPage: 'index',
          type: 'index',
        });
      } else if (currentPageType === 'future') {
        const featureCount = location.pathname.split('/')[2];
        window.artworkGL.emit('UPDATE_TRANSITION', {
          nextPage: 'future' + featureCount,
          futureNum: +featureCount - 1,
          type: 'future',
        });
      } else if (!noTransition) {
        // other(=indexとfutureではない)かつトランジション対象外ではない
        window.artworkGL.emit('UPDATE_TRANSITION', {
          nextPage: 'other',
          type: 'other',
        });
      }

      // TOP→13の未来詳細の特殊トランジション開始
      if ((previousPage?.type === 'index' || !previousPage) && currentPageType === 'future')
        dispatch(updateFutureTransition(true));
    }

    // 直前に表示したページの種類を更新
    // artworkGLのトランジションが再生完了する前に次のlocation変更が発生しても直前のpageTypeを判別できるよう、ここで更新する
    dispatch(
      updatePreviousPage({
        pathname: location.pathname,
        type: currentPageType,
      })
    );

    // URLにハッシュタグを含んでいない場合はページトップにスクロール位置をリセット
    if (!location.hash && body.current) body.current.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {/* 遷移元コンテンツによってアニメーションの挙動を分岐させるため data-from 付与  */}
      <div ref={body} className={[styles.body].join(' ')} data-from={fromType}>
        <div
          aria-hidden={!hasPageTitle}
          className={[
            styles.stripeBackground,
            location.pathname === '/' || changeColor ? styles['--invisible'] : '',
          ].join(' ')}
        ></div>
        <LayoutPageTitle
          /**
           * 素早く遷移を繰り返すと、usePageTitleが更新されるまでのタイムラグで
           * pageTitle表示対象ではないページ(=slugが割り付けられていないページ)のテキストが一瞬表示されるので
           * 条件分岐を挟んで対象のページのテキストだけを渡す
           * ただし、404ページはslugが割り付けられていないが、pageTitleを表示するので除外
           */
          title={currentPage?.slug || currentPage?.label === '404' ? currentPage?.label : ''}
          copy={currentPage?.slug || currentPage?.label === '404' ? currentPage?.copy : ''}
          active={hasPageTitle}
          invisible={isWritingContentData || changeColor || defaultTransition}
          addClass={[styles.pageTitle]}
        />
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={animationDuration}
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              exit: styles.exit,
              exitActive: styles.exitActive,
            }}
            unmountOnExit
          >
            <div
              ref={nodeRef}
              data-type={currentPageType}
              aria-hidden={!visibleContents}
              className={[styles.page].join(' ')}
            >
              {currentOutlet}
            </div>
          </CSSTransition>
        </SwitchTransition>
        {baseColor === 'light' && location.pathname !== '/' && (
          <LayoutFooter
            addClass={[
              // 13の未来詳細: ぱんくずの背景を白く塗りつぶしてイラストの透けを防ぐ
              !!location.pathname.match(/^\/futures\/(\d+)$/i) ? styles.futuresFooter : '',
            ]}
          />
        )}
        <LayoutCopyright
          base={baseColor}
          addClass={[styles.copyright, initialize ? styles['--initialize'] : '']}
        />
        {initialize && (
          <LayoutNavigation
            base={baseColor}
            addClass={[
              // TOP→13の未来詳細: artworkGLのトランジションが再生中はナビを非表示にする
              !!location.pathname.match(/^\/futures\/(\d+)$/i) &&
              !!futureTransition &&
              defaultTransition
                ? 'nowTransition'
                : '',
              // 13の未来一覧: ワイドスクリーンレイアウトでナビを本文と一緒にスクロールさせる。
              !!location.pathname.match(/^\/futures(\/)?$/i) ? 'isFuturesIndex' : '',
              // 13の未来詳細: ヘッダー部分は画面上に固定させ本文の背後に潜らせ、メニューは前面に残す。
              !!location.pathname.match(/^\/futures\/(\d+)$/i) ? 'isFuturesDetail' : '',
            ]}
          />
        )}
      </div>
    </>
  );
};
