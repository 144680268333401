import { FC } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

import { usePageTitle } from 'hooks/usePageTitle';

import styles from './Home.module.scss';

import { IndexList } from './components/IndexList';
import { Opening } from './components/Opening';

export const Home: FC = () => {
  // 初回表示済フラグ
  const { initialize } = useSelector((state: RootState) => state.user);

  // ページタイトル
  usePageTitle(process.env.REACT_APP_WEBSITE_TITLE ?? '');

  return (
    <div className={styles.home}>
      {!initialize ? <Opening /> : <div className={styles.screen}></div>}
      <div className={[styles.body, initialize ? styles[`--visible`] : ''].join(' ')}>
        <IndexList />
      </div>
    </div>
  );
};
