import { FC } from 'react';

import { useParams, Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

import { usePageTitle } from 'hooks/usePageTitle';

import styles from './ProjectDetail.module.scss';

import { Collaborates } from './components/Collaborates';
import { Introduction } from './components/Introduction';
import { ProjectLog } from './components/ProjectLog';
import { RelationFutures } from './components/RelationFutures';

export const ProjectDetail: FC = () => {
  // パラメータを取得
  const { slug } = useParams<string>();
  const { list } = useSelector((state: RootState) => state.project);

  // 選択中の記事
  const currentArticle = list.find(index => index.id === slug);

  // titleタグの設定
  usePageTitle(currentArticle ? currentArticle.title : '');

  // slugと紐づく記事がない場合は404ページへリダイレクト
  return !currentArticle ? (
    <Navigate replace to="/404" />
  ) : (
    <article className={styles.projectDetail}>
      <div className={styles.stripeBackground}></div>
      <Introduction
        image={currentArticle.image}
        title={currentArticle.title}
        introductionTitle={currentArticle.introductionTitle}
        description={currentArticle.description}
      />
      <div className={styles.body}>
        <RelationFutures futures={currentArticle.futures} />

        {currentArticle.collaborates && <Collaborates list={currentArticle.collaborates} />}

        {currentArticle.projectLog.length > 0 && (
          <ProjectLog data={currentArticle.projectLog} title={currentArticle.title} />
        )}

        {currentArticle.body && (
          <div
            className={styles.free}
            dangerouslySetInnerHTML={{ __html: currentArticle.body }}
          ></div>
        )}
      </div>
    </article>
  );
};
