import { FC } from 'react';

import styles from './CollaboratesItem.module.scss';

import type { ProjectCollaboratesData } from 'types/project';

type CollaboratesItemProps = ProjectCollaboratesData & {
  addClass?: string[];
};

export const CollaboratesItem: FC<CollaboratesItemProps> = (collaborate, { addClass = [] }) => {
  return (
    <li className={[styles.item, ...addClass].join(' ')}>
      <figure className={styles.figure}>
        <img className={styles.image} src={collaborate.image} alt="" />
      </figure>
      <div>
        <div className={styles.head}>
          <h4 className={styles.name}>{collaborate.name}</h4>
          {collaborate.position && <span className={styles.position}>{collaborate.position}</span>}
        </div>
        <div className={styles.body}>{collaborate.body}</div>
        {collaborate.relation && (
          <ul className={styles.relation}>
            {collaborate.relation.map((item, index) => (
              <li key={index} className={styles.relationItem}>
                <a
                  className={styles.relationLink}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label ?? item.url}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};
