import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import event from './event';
import futures from './futures';
import project from './project';
import report from './report';
import sitemap from './sitemap';
import update from './update';
import user from './user';

const reducer = combineReducers({
  event,
  report,
  sitemap,
  project,
  user,
  update,
  futures,
});

const middleware = getDefaultMiddleware({ serializableCheck: false });
export const store = configureStore({ reducer, middleware });

export type RootState = ReturnType<typeof reducer>;
