import { FC, useMemo } from 'react';

import styles from './ProjectLogItem.module.scss';

import type { ProjectLogData } from 'types/project';

type ProjectLogItemProps = ProjectLogData & {
  addClass?: string[];
};

export const ProjectLogItem: FC<ProjectLogItemProps> = (log, { addClass = [] }) => {
  const label = useMemo(() => {
    switch (log.type as 'magazine' | 'events' | 'reports') {
      case 'events':
        return 'EVENT';
        break;
      case 'reports':
        return 'REPORT';
        break;
      case 'magazine':
        return 'MAGAZINE';
        break;
      default:
        return 'LOG';
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [log.type]);
  return (
    <a className={[styles.item, ...addClass].join(' ')} href={log.link}>
      <div className={styles.header}>
        <span className={styles.date}>{log.date}</span>
        <span className={styles.type}>{label}</span>
      </div>
      <div className={styles.title}>
        <span>{log.title}</span>
      </div>
      <figure
        className={[styles.figure, log.type === 'magazine' ? styles['--magazine'] : ''].join(' ')}
      >
        <img src={log.image || `/assets/no-image.webp`} className={styles.image} alt="" />
      </figure>
    </a>
  );
};
