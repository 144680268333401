import { FC } from 'react';

import { CollaboratesItem } from 'components/pages/ProjectDetail/components/CollaboratesItem';

import styles from './Collaborates.module.scss';

import type { ProjectCollaboratesData } from 'types/project';

type CollaboratesProps = {
  list: ProjectCollaboratesData[];
  addClass?: string[];
};

export const Collaborates: FC<CollaboratesProps> = ({ list, addClass = [] }) => {
  return (
    <article className={[styles.collaborates, ...addClass].join(' ')}>
      <header className={styles.headline}>
        <h2>コラボレーター</h2>
      </header>
      <div className={styles.body}>
        <ul className={styles.list}>
          {list.map((collaborate, index) => (
            <CollaboratesItem key={index} {...collaborate} />
          ))}
        </ul>
      </div>
    </article>
  );
};
