import { FC } from 'react';

import styles from './Pagination.module.scss';

import { usePagination } from './usePagination';

type PaginationProps = {
  totalCount: number;
  startPage: number;
  max?: number;
  addClass?: string[];
};

export const Pagination: FC<PaginationProps> = ({ totalCount, startPage, max = 10, addClass }) => {
  const args = usePagination(totalCount, startPage, max);
  return <PaginationView {...args} addClass={addClass} />;
};

type PaginationViewProps = {
  pages: number;
  visiblePages: (number | '…')[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isEarly: boolean;
  isEnd: boolean;
  isSeparate: boolean;
  addClass?: string[];
};

export const PaginationView: FC<PaginationViewProps> = ({
  pages,
  visiblePages,
  currentPage,
  setCurrentPage,
  isEarly,
  isEnd,
  isSeparate,
  addClass = [],
}) => {
  return (
    <>
      {pages > 1 ? (
        <div className={[styles.pagination, ...addClass].join(' ')}>
          <button
            type="button"
            className={styles.preview}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <svg
              className={styles.arrow}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 6 10"
              role="img"
              aria-labelledby="pagination-preview"
            >
              <desc id="pagination-preview">preview page</desc>
              <polyline fill="none" stroke="#000" points="5.243,0.757 1,5 5.243,9.243 " />
            </svg>
          </button>
          {visiblePages.map((item, index) => {
            const isMore = item === '…';
            const isNear =
              (isSeparate && (item === currentPage - 1 || item === currentPage + 1)) ||
              (isEarly && item === 4) ||
              (isEnd && item === pages - 3);
            const isRemainder =
              (isEarly && currentPage === 3 && item === 2) ||
              (isEnd && currentPage === pages - 2 && item === pages - 1);
            const classNameList = [styles.page];
            if (isMore) classNameList.push(styles['--more']);
            if (isNear) classNameList.push(styles['--near']);
            if (isRemainder) classNameList.push(styles['--remainder']);
            return (
              <button
                type="button"
                key={index}
                aria-checked={currentPage === item ? 'true' : undefined}
                disabled={currentPage === item || item === '…'}
                className={classNameList.join(' ')}
                onClick={() => setCurrentPage(+item)}
              >
                {item}
              </button>
            );
          })}
          <button
            type="button"
            className={styles.next}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === pages}
          >
            <svg
              className={styles.arrow}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 6 10"
              role="img"
              aria-labelledby="pagination-next"
            >
              <desc id="pagination-next">next page</desc>
              <polyline fill="none" stroke="#000" points="1,0.757 5.243,5 1,9.243 " />
            </svg>
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
