import { createSlice } from '@reduxjs/toolkit';

import list from 'data/project.json';

import type { ProjectData } from 'types/project';
type State = {
  list: ProjectData[];
};

const initialState: State = {
  list,
};

const project = createSlice({
  name: 'project',

  initialState,

  reducers: {},
});

// Action Creator
// export const {} = project.actions;

// Reducer
export default project.reducer;
