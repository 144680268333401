import { FC } from 'react';

import styles from './IconExternalLink.module.scss';

type IconExternalLinkProps = {
  addClass?: string[];
};

export const IconExternalLink: FC<IconExternalLinkProps> = ({ addClass = [] }) => {
  return (
    <div className={[styles.iconExternalLink, ...addClass].join(' ')}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        aria-labelledby="icon-external-link"
      >
        <desc id="icon-external-link">新しいウィンドウでリンクを開きます</desc>
        <path
          d="M14.2,14.2H1.8V1.8H8V0H1.8C0.8,0,0,0.8,0,1.8v12.5C0,15.2,0.8,16,1.8,16h12.5c0.9,0,1.8-0.8,1.8-1.8V8h-1.8
          V14.2z M9.8,0v1.8H13l-8.7,8.7l1.2,1.2L14.2,3v3.2H16V0H9.8z"
        />
      </svg>
    </div>
  );
};
