import { ReactNode } from 'react';

import ReactDOMServer from 'react-dom/server';

/**
 * ReactNode型のタグを含む値を文字列に変換するhooks
 * @param input ReactNode型の入力値
 * @returns タグが削除された文字列
 */
export const useToPlainText = (input: ReactNode): string => {
  const htmlString = ReactDOMServer.renderToStaticMarkup(<>{input}</>);
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlString, 'text/html');

  // aria-hidden="true" 属性を持つ要素とその子孫要素を削除
  const spanElements = parsedHtml.querySelectorAll('[aria-hidden="true"]');
  spanElements.forEach(spanElement => {
    spanElement.parentNode!.removeChild(spanElement);
  });

  const plainText = parsedHtml.body.textContent || '';

  return plainText;
};
