import { FC } from 'react';

import { ProjectLogItem } from 'components/pages/ProjectDetail/components/ProjectLogItem';

import styles from './ProjectLog.module.scss';

import type { ProjectLogData } from 'types/project';

type ProjectLogProps = {
  data: ProjectLogData[];
  title: string;
  addClass?: string[];
};

export const ProjectLog: FC<ProjectLogProps> = ({ data, title, addClass = [] }) => {
  return (
    <article className={[styles.ProjectLog, ...addClass].join(' ')}>
      <header className={styles.headline}>
        <h2>プロジェクトの記録</h2>
      </header>
      <div className={styles.body}>
        <div className={styles.list}>
          {data.map(log => (
            <ProjectLogItem key={log.id} addClass={[styles.item]} {...log} />
          ))}
          {/* <div className={[styles.item, styles['--start']].join(' ')}>
            プロジェクトスタート
            <br className="showNarrow" aria-hidden="true" />
            <span className={styles.name}>{title}</span>
          </div> */}
        </div>
      </div>
    </article>
  );
};
