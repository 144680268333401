import { FC } from 'react';

import { ReportItem } from 'components/pages/Reports/components/ReportItem';

import { Headline } from '../Headline';

import styles from './Report.module.scss';

import { useReport } from './useReport';

import type { ReportIndexData } from 'types/report';

type ReportProps = {
  id: number;
  addClass?: string[];
};
export const Report: FC<ReportProps> = ({ id, addClass }) => {
  const { list } = useReport(id);
  return list.length ? <ReportView list={list} addClass={addClass} /> : <></>;
};

type ReportViewProps = {
  list: ReportIndexData[];
  addClass?: string[];
};
export const ReportView: FC<ReportViewProps> = ({ list, addClass = [] }) => {
  return (
    <article className={[styles.report, ...addClass].join(' ')}>
      <Headline title="REPORT" subTitle="レポート" addClass={[styles.header]} />
      <div className={styles.body}>
        <div className={styles.list}>
          {list.map(item => (
            <ReportItem key={item.id} {...item} />
          ))}
        </div>
      </div>
    </article>
  );
};
