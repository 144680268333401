import { useSelector } from 'react-redux';

import { RootState } from 'store';

import type { FuturesSignalData } from 'types/futures';

export const useSignal = (id: number) => {
  const allList = useSelector((state: RootState) => state.futures.signal);

  // 仕分けする
  const currentList: FuturesSignalData[] = id
    ? allList.filter(article => article.futures.includes(id))
    : [];

  // 優先プロジェクトを仕分けする
  const vipSignalTable = useSelector((state: RootState) => state.futures.vipSignal);
  const vip = vipSignalTable.filter(category => category.id === id)[0]?.vip || [];

  // currentListから優先プロジェクトの情報を抽出する
  const vipList = vip
    .map(vipId => currentList.find(article => article.id === vipId))
    .filter(Boolean) as FuturesSignalData[]; // undefinedを削除

  // currentListから優先プロジェクトを除外する
  const list = currentList.filter(article => !vip.includes(article.id));

  return { vipList, list };
};
