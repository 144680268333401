import { FC, memo } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { RootState } from 'store';

import useZeroPadding from 'hooks/useZeroPadding';

import styles from './FuturesList.module.scss';

import type { FuturesIndexItemData } from 'types/futures';

export const FuturesList: FC = () => {
  const location = useLocation();

  // 13の未来一覧のデータを取得
  const { indexList } = useSelector((state: RootState) => state.futures);

  // 0詰めした2桁の文字列を返す
  const zeroPaddingNum = useZeroPadding;

  // memoでIndexItemを定義
  const IndexItem = memo(({ no, keyword, body, enSubTitle }: FuturesIndexItemData) => (
    <li className={styles.item}>
      <Link
        to={`/futures/${no}`}
        className={styles.link}
        aria-current={location.pathname === `/futures/${no}` ? 'page' : undefined}
      >
        <span className={styles.no}>{zeroPaddingNum(no)}.</span>
        <span className={styles.keyword}>
          <span className={styles.human} data-text="人">
            人
          </span>
          <span className={styles.corroborate}>✕</span>
          {keyword}
        </span>
      </Link>
    </li>
  ));

  return (
    <div className={styles.FuturesList}>
      <ul className={styles.list}>
        {indexList.map(item => (
          <IndexItem key={item.no} {...item} />
        ))}
      </ul>
    </div>
  );
};
