import { FC, useState, useCallback, useMemo } from 'react';

import { Headline } from '../Headline';
import { SignalItem } from '../SignalItem';

import styles from './Signal.module.scss';

import { useSignal } from './useSignal';

import type { FuturesSignalData } from 'types/futures';

type SignalProps = {
  id: number;
  addClass?: string[];
};
export const Signal: FC<SignalProps> = ({ id, addClass = [] }) => {
  const { vipList, list } = useSignal(id);
  return list.length ? <SignalView vipList={vipList} list={list} addClass={addClass} /> : <></>;
};

type SignalViewProps = {
  vipList: FuturesSignalData[];
  list: FuturesSignalData[];
  addClass?: string[];
};
export const SignalView: FC<SignalViewProps> = ({ vipList = [], list = [], addClass = [] }) => {
  // listから最初の6件だけ初期表示させる
  const [currentData, setCurrentData] = useState<FuturesSignalData[]>(vipList);

  // 全件表示しているかどうか
  const isShowAll = useMemo(
    () => currentData.length > vipList.length,
    [currentData.length, vipList.length]
  );

  // リクエストがあったら全件表示される
  const handleSwitchShowLength = useCallback(() => {
    setCurrentData(pre => {
      // 要素を減らす場合は、閉じたとき画面外へ見切れないようSIGNALS先頭へスクロール
      const body = document.querySelector('[class*="Layout_body"]');
      const self = document.querySelector(
        '[class*="FuturesDetail_garageProgram"]'
      ) as HTMLDivElement;
      if (body && self && isShowAll) {
        // scrollParentのmargin-topの値を取得
        const scrollParent = document.querySelector('[class*="FuturesDetail_futuresDetail"]');
        if (scrollParent) {
          // 直接の親要素のmarginTopを取得
          const marginTop = parseInt(getComputedStyle(scrollParent).marginTop);

          // スクロールさせる値
          const positionY = self?.offsetTop;

          // Safariはself.offsetTopの値がmarginTop込、Chromeはなしなので分岐させる
          const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          // スクロールさせる
          if (positionY)
            body.scrollTo(
              0,
              positionY + (safari ? window.innerHeight / 2 : marginTop) + window.innerHeight / 2
            );
        }
      }

      return isShowAll ? vipList : [...vipList, ...list];
    });
  }, [isShowAll, vipList, list]);

  return (
    <article
      className={[styles.signal, ...addClass, isShowAll ? styles['--isShowAll'] : ''].join(' ')}
    >
      <Headline addClass={[styles.header]} title="SIGNALS" subTitle="シグナル" />
      <div className={styles.list}>
        {vipList.map((item, index) => (
          <SignalItem key={`vip_${index}`} {...item} />
        ))}
      </div>
      <div className={styles.list}>
        {list.map(item => (
          <SignalItem key={item.id} {...item} />
        ))}
      </div>
      <footer className={styles.footer}>
        <button className={styles.showMoreButton} onClick={handleSwitchShowLength}></button>
      </footer>
    </article>
  );
};
