import { FC } from 'react';

import styles from './Copy.module.scss';

type CopyProps = {
  base?: 'dark' | 'light';
  addClass?: string[];
};

export const Copy: FC<CopyProps> = ({ base = 'light', addClass = [] }) => {
  return (
    <div className={[styles.copy, ...addClass, base && styles[`--${base}`]].join(' ')}>
      GARAGE Programから見えてきた未来社会のすがた
    </div>
  );
};
